import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import { useFlag } from '@dashboard-experience/react-flagr';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  updateParentWindowUrl,
  useTrackEvent,
  BETTER_ORDER_EVENT_NAMES,
  PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES,
} from 'utils';
import UIContext from 'context/UI';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import AccountContext from 'pages/Account/AccountContext';
import {
  buildPostBodyWithAddOns,
  mapPackage,
} from 'components/AddScreenings/shared/utils';
import { PostPackageType } from '@dashboard-experience/utils';
import { ALIAS_AS_AN_ADDON } from 'Flags';
import type { AddonsT } from '.';
import { StyledFooter, StyledStepContainer } from '../ui';
import { STEPS } from '../utils/constants';
import { DomesticScreeningsList } from './DomesticScreeningsList';
import { PopularAddOns } from './PopularAddOns';
import { useGetScreeningPrices } from '../hooks/useGetScreeningPrices';
import {
  StyledTitle,
  StyledFlexColumn,
  StyledFlexPositionRelative,
  StyledRecommendationsSectionWrapper,
  StyledRecommendationsContainer,
} from './AddonsStep.styles';
import { OrderSummary } from './OrderSummary';
import InternationalScreeningsList from './InternationalScreeningsList/InternationalScreeningsList';
import { useCreatePackage } from '../hooks/useCreatePackage';
import { ScreeningClassMap, ScreeningProductType } from './AddonsStep.enums';
import { namespace } from '../locales';
import RecommendationsSection from './RecommendationsSection/RecommendationsSection';
import useGetRecommendedAddOns from '../hooks/useGetRecommendationsAddons';
import { PACKAGE_RECOMMENDATION_SIGNUP } from '../../../Flags';
import {
  StyledHeaderSection,
  StyledQuestion,
} from '../../Signup/ui/PurchaseRecommendation/PurchaseRecommendationModal.styles';
import { getPricesByScreeningType } from '../utils/PricingUtils';
import DomesticScreeningsListWrapper from './DomesticScreeningsList/DomesticScreeningsListWrapper';

const AddonsStep: React.FC<StepProps> = ({ setCurrentStep }) => {
  const trackEvent = useTrackEvent();
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns',
  });
  const [
    selectedAddedScreeningTypeWithPrices,
    setSelectedAddedScreeningTypeWithPrices,
  ] = useState<AddonsT.ScreeningTypeWithPrices[]>([]);
  const [selectedAdditionalProperties, setSelectedAdditionalProperties] =
    useState<AddonsT.AdditionalProperties>({});

  const history = useHistory();
  const { contextId } = useContext(UIContext);
  const location = useLocation<AddonsT.LocationState>();
  const [selectedRole, setSelectedRole] = useState<{
    name: string;
    id: string;
  }>({ name: '', id: '' });

  const aliasEnabled = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';

  const {
    selectedPackage,
    update,
    additionalProperties,
    addedScreeningTypeWithPrices,
    localeType,
    pricesSummary: previouslySavedPricesSummary,
    newPackageName: previouslySavedNewPackageName,
    saveForNextTime: initialSaveForNextTime,
    aliasAdded,
    infoSource,
    infoMethod,
  } = useContext(BetterOrderExperienceContext);

  const { account } = useContext(AccountContext);
  const { call: createPackage } = useCreatePackage({
    accountId: account.id ?? '',
    onSuccess: (data: { [key: string]: unknown }) =>
      update({
        newlyCreatedPackage: mapPackage(data) as PostPackageType,
      }),
  });

  const purchaseRecommendationEnabled =
    useFlag(PACKAGE_RECOMMENDATION_SIGNUP)?.variantKey === 'on';

  const isManualInvite = infoSource === 'CANDIDATE' && infoMethod === 'MANUAL';

  const showPurchaseRecommendation =
    purchaseRecommendationEnabled && isManualInvite;

  const initialAliasIsChecked =
    location.state?.from !== STEPS.REVIEW_AND_SUBMIT.path
      ? account.alias_auto_enable
      : aliasAdded === 'on';

  const [newPackageName, setNewPackageName] = useState(
    previouslySavedNewPackageName,
  );
  const [saveForNextTime, setSaveForNextTime] = useState(
    initialSaveForNextTime,
  );

  const [pricesSummary, setPricesSummary] = useState(
    previouslySavedPricesSummary,
  );
  const [aliasIsChecked, setAliasIsChecked] = useState(initialAliasIsChecked);

  const isInternational = localeType === 'INTERNATIONAL';

  const addOnPrices = useGetScreeningPrices(account.id ?? '', !isInternational);
  const addedScreeningTypes = useMemo(() => {
    return selectedAddedScreeningTypeWithPrices.map(
      screening => screening.screening.type,
    );
  }, [selectedAddedScreeningTypeWithPrices]);
  const includedScreenings = useMemo(() => {
    if (
      !selectedPackage?.screenings ||
      selectedPackage?.screenings?.length === 0
    )
      return [];
    return selectedPackage?.screenings.map(
      screening => (screening as { type: string }).type,
    );
  }, [selectedPackage?.screenings]);

  useEffect(() => {
    // Fail safe for when the user uses the browser's back button upon submitting an order successfully.
    if (!selectedPackage?.name) {
      history.replace(STEPS.GET_STARTED.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set initial values from any persisted data
    if (additionalProperties)
      setSelectedAdditionalProperties(additionalProperties);
    if (addedScreeningTypeWithPrices)
      setSelectedAddedScreeningTypeWithPrices(addedScreeningTypeWithPrices);
  }, [addedScreeningTypeWithPrices, additionalProperties]);

  const handleNewPackageName = useCallback(
    (name: string) => {
      setNewPackageName(name);
    },
    [setNewPackageName],
  );

  const handleSaveForNextTime = useCallback(
    (save: boolean) => {
      setSaveForNextTime(save);
    },
    [setSaveForNextTime],
  );

  const createNewPackage = useCallback(
    (packageName: string, isPrivate: boolean) => {
      const addedScreeningTypes = selectedAddedScreeningTypeWithPrices.map(
        s => s.screening.type,
      );

      const body = buildPostBodyWithAddOns({
        basePackage: {
          ...selectedPackage,
          aliases_enabled: aliasIsChecked && aliasEnabled ? 'on' : 'off',
        },
        addedScreeningTypes,
        additionalProperties: selectedAdditionalProperties,
        packageName,
        setSlug: !isPrivate, // Only send in slugs for 'Save for later' packages
        isPrivate,
      });

      createPackage(body);
    },
    [
      aliasIsChecked,
      selectedAddedScreeningTypeWithPrices,
      selectedPackage,
      selectedAdditionalProperties,
      createPackage,
      aliasEnabled,
    ],
  );

  const handleBack = useCallback(() => {
    const { path } = STEPS.SELECT_YOUR_PACKAGE;
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.SELECT_YOUR_PACKAGE);
  }, [contextId, history, setCurrentStep]);

  const handleContinue = useCallback(() => {
    const selectedPackageHasAlias = selectedPackage.aliases_enabled === 'on';

    // If no new screenings are added and alias is the same as the base package, remove newlyCreatedPackage
    if (
      selectedAddedScreeningTypeWithPrices.length === 0 &&
      aliasIsChecked === selectedPackageHasAlias
    ) {
      update({
        newlyCreatedPackage: {} as PostPackageType,
        aliasAdded: aliasIsChecked && aliasEnabled ? 'on' : 'off',
      });
    }

    // Only create new package if there are selected add-ons
    else if (
      selectedAddedScreeningTypeWithPrices.length > 0 ||
      (aliasIsChecked && aliasEnabled !== selectedPackageHasAlias)
    ) {
      if (saveForNextTime && newPackageName) {
        // Create package with custom name if provided
        createNewPackage(newPackageName, false);
        update({ newPackageName, aliasAdded: aliasIsChecked ? 'on' : 'off' });
      } else {
        // Create package with default name if not saving for next time
        createNewPackage(`${selectedPackage.name} with addons`, true);
        update({ aliasAdded: aliasIsChecked ? 'on' : 'off' });
      }
    }

    trackEvent(BETTER_ORDER_EVENT_NAMES.CUSTOMIZE_ADD_ONS_COMPLETED, {
      'Add-on Screenings': selectedAdditionalProperties,
      'Package Saved':
        selectedAddedScreeningTypeWithPrices.length > 0 && newPackageName
          ? 'Yes'
          : 'No',
    });

    // TODO: Move below to onSuccess
    const { path } = STEPS.REVIEW_AND_SUBMIT;
    update({
      additionalProperties: selectedAdditionalProperties,
      addedScreeningTypeWithPrices: selectedAddedScreeningTypeWithPrices,
      saveForNextTime,
      pricesSummary,
    });
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.REVIEW_AND_SUBMIT);
  }, [
    selectedPackage.aliases_enabled,
    selectedPackage.name,
    selectedAddedScreeningTypeWithPrices,
    aliasIsChecked,
    aliasEnabled,
    pricesSummary,
    update,
    trackEvent,
    selectedAdditionalProperties,
    saveForNextTime,
    contextId,
    setCurrentStep,
    newPackageName,
    createNewPackage,
    history,
  ]);

  const onAddClick = useCallback(
    (
      screening: AddonsT.ScreeningType,
      price: string,
      additionalProperties?: AddonsT.AdditionalProperties,
    ) => {
      // Check if the screening has already been added
      const hasBeenAdded = selectedAddedScreeningTypeWithPrices?.some(
        addOn => addOn?.screening?.type === screening,
      );
      const newAddonsSelection = {
        screening: { type: screening },
        price,
      };

      if (
        (screening === ScreeningProductType.EMPLOYMENT_VERIFICATION ||
          screening ===
            ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION ||
          screening === ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION) &&
        hasBeenAdded
      ) {
        // replace the existing object in the state with the new selection
        const addOnsWithoutEmp = selectedAddedScreeningTypeWithPrices.filter(
          addOn => addOn.screening.type !== screening,
        );
        setSelectedAddedScreeningTypeWithPrices([
          ...addOnsWithoutEmp,
          newAddonsSelection,
        ]);
      }

      if (!hasBeenAdded) {
        setSelectedAddedScreeningTypeWithPrices(addOns => [
          ...addOns,
          newAddonsSelection,
        ]);
      }

      if (additionalProperties)
        setSelectedAdditionalProperties({
          ...selectedAdditionalProperties,
          ...additionalProperties,
        });
    },
    [selectedAdditionalProperties, selectedAddedScreeningTypeWithPrices],
  );

  const onRemoveClick = useCallback(
    (screening: AddonsT.ScreeningType) => {
      setSelectedAddedScreeningTypeWithPrices(addOn =>
        addOn.filter(addon => addon.screening.type !== screening),
      );

      const newAdditionalProperties = omit(
        selectedAdditionalProperties,
        screening,
      );
      setSelectedAdditionalProperties(newAdditionalProperties);
    },
    [selectedAdditionalProperties],
  );

  /* Recommended Add Ons logic */
  const [selectedRolesAttr, setSelectedRolesAttr] = useState<number[]>([]);
  const {
    call: getRecommendedAddOns,
    isLoading: recommendedAddOnsLoading,
    data: recommendedAddOns,
  } = useGetRecommendedAddOns(selectedRolesAttr);

  const recommendedAddOnsList: Set<ScreeningProductType> = useMemo(() => {
    const result: Set<ScreeningProductType> = new Set(
      (recommendedAddOns ?? [])
        .map((className: string) => {
          const mappedClass = ScreeningClassMap[className] ?? null;
          return mappedClass;
        })
        .filter(Boolean),
    );
    return result;
  }, [recommendedAddOns]);

  const handleAddAllChecks = useCallback(() => {
    // TODO: handle Add All Checks click
    const skipAddOns = [
      ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING,
      ScreeningProductType.DRUG_SCREENING,
      ScreeningProductType.FACIS_SEARCH,
    ];
    recommendedAddOnsList.forEach(addon => {
      if (
        !Object.values(ScreeningProductType).includes(addon) ||
        skipAddOns.includes(addon)
      )
        return;

      const price = getPricesByScreeningType(addOnPrices, addon);
      onAddClick(addon, price, additionalProperties);
    });

    trackEvent(
      PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.ALL_RECOMMENDATIONS_ADDED,
    );
  }, [addOnPrices, additionalProperties, onAddClick, recommendedAddOnsList]);

  useEffect(() => {
    if (selectedRolesAttr.length > 0) {
      getRecommendedAddOns();
    }
  }, [getRecommendedAddOns, selectedRolesAttr]);

  return (
    <StyledStepContainer data-testid='addons-step-container'>
      <StyledTitle>{t('title')}</StyledTitle>
      <StyledFlexPositionRelative>
        <StyledFlexColumn flexDirection='column'>
          {!isInternational ? (
            <>
              {showPurchaseRecommendation ? (
                <StyledRecommendationsSectionWrapper>
                  <RecommendationsSection
                    recommendedAddOnsList={recommendedAddOnsList}
                    setSelectedRolesAttr={setSelectedRolesAttr}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                  />
                  {recommendedAddOnsLoading && (
                    <M.LoadingInline description='Searching...' />
                  )}
                  {selectedRolesAttr.length > 0 && (
                    <StyledRecommendationsContainer>
                      <StyledHeaderSection>
                        <StyledQuestion>
                          <h5 className='mb-0'>
                            {t('recommendationsSection.checksTitle', {
                              roleName: selectedRole.name,
                            })}
                          </h5>
                        </StyledQuestion>
                        <M.Button
                          kind='tertiary'
                          data-testid='add-all-checks-button'
                          onClick={handleAddAllChecks}
                        >
                          <M.Icon icon='Add' />{' '}
                          {t('recommendationsSection.addAllChecksBtn')}
                        </M.Button>
                      </StyledHeaderSection>
                      <div className='recommended-screenings-wrap'>
                        {/* TODO (Alice): Remove recommended screenings list from DomesticScreeningsList and add to its own component */}
                        <DomesticScreeningsList
                          onAddClick={onAddClick}
                          onRemoveClick={onRemoveClick}
                          addedScreeningTypes={addedScreeningTypes}
                          addOnPrices={addOnPrices}
                          includedScreenings={
                            includedScreenings as AddonsT.ScreeningType[]
                          }
                          selectedAdditionalProperties={
                            selectedAdditionalProperties
                          }
                          setSelectedAdditionalProperties={
                            setSelectedAdditionalProperties
                          }
                          recommendedAddOnsList={recommendedAddOnsList}
                          recommendationsSection
                        />
                      </div>
                    </StyledRecommendationsContainer>
                  )}
                </StyledRecommendationsSectionWrapper>
              ) : (
                <PopularAddOns
                  onAddClick={onAddClick}
                  onRemoveClick={onRemoveClick}
                  addOnPrices={addOnPrices}
                  includedScreenings={
                    includedScreenings as AddonsT.ScreeningType[]
                  }
                  selectedPackage={selectedPackage}
                  additionalProperties={additionalProperties}
                  addedScreeningTypes={addedScreeningTypes}
                />
              )}
              <DomesticScreeningsListWrapper
                showRecommendations={showPurchaseRecommendation}
                onAddClick={onAddClick}
                onRemoveClick={onRemoveClick}
                addedScreeningTypes={addedScreeningTypes}
                addOnPrices={addOnPrices}
                includedScreenings={
                  includedScreenings as AddonsT.ScreeningType[]
                }
                selectedAdditionalProperties={selectedAdditionalProperties}
                setSelectedAdditionalProperties={
                  setSelectedAdditionalProperties
                }
                recommendationsSection={false}
              />
            </>
          ) : (
            <InternationalScreeningsList
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              addedScreeningTypes={addedScreeningTypes}
              addOnPrices={addOnPrices}
              includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
              selectedAdditionalProperties={selectedAdditionalProperties}
              setSelectedAdditionalProperties={setSelectedAdditionalProperties}
            />
          )}
        </StyledFlexColumn>
        <OrderSummary
          selectedPackage={selectedPackage}
          setPricesSummary={setPricesSummary}
          aliasIsChecked={aliasIsChecked}
          setAliasIsChecked={setAliasIsChecked}
          newPackageName={newPackageName}
          setNewPackageName={handleNewPackageName}
          saveForNextTime={saveForNextTime}
          setSaveForNextTime={handleSaveForNextTime}
          additionalProperties={selectedAdditionalProperties}
          addedScreeningTypeWithPrices={selectedAddedScreeningTypeWithPrices}
        />
      </StyledFlexPositionRelative>

      <StyledFooter>
        <M.Button
          data-testid='addons-step-back-btn'
          type='button'
          kind='secondary'
          onClick={handleBack}
        >
          {t('footerButtons.goBack')}
        </M.Button>

        <M.Button
          type='button'
          data-testid='addons-step-continue-btn'
          onClick={handleContinue}
          disabled={saveForNextTime && !newPackageName}
        >
          {t('footerButtons.continue')}
        </M.Button>
      </StyledFooter>
    </StyledStepContainer>
  );
};

export default AddonsStep;
