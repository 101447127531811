import React, { useCallback, useEffect } from 'react';
import {
  accountHasPermissions,
  CurrentUser,
  hasPermission,
} from '@dashboard-experience/utils';
import { useUser } from 'modules/assess/ui/hooks';
import {
  PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import { ScreeningProductType } from '../AddonsStep.enums';
import MotorVehicleRecordSection from './MotorVehicleRecordsSection/MotorVehicleRecordsSection';
import VerificationSection from './VerificationSection/VerificationSection';
import CriminalSearchesSection from './CriminalSearchesSection/CriminalSearchesSection';
import DrugAndHealthSection from './DrugAndHealthSection/DrugAndHealthSection';
import FACISSection from './FACISSection/FACISSection';
import CivilSearchesSection from './CivilSearchesSection/CivilSearchesSection';
import CreditReportSection from './CreditReportSection/CreditReportSection';
import type { AddonsT } from '../..';
import { DomesticScreeningsListContainer } from './DomesticScreeningsList.styles';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addedScreeningTypes: AddonsT.ScreeningType[];
  addOnPrices: AddonsT.AddonPrice[];
  includedScreenings: AddonsT.ScreeningType[];
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
  recommendedAddOnsList?: Set<string>;
  recommendationsSection?: boolean;
};

const DomesticScreeningsList: React.FC<Props> = props => {
  const {
    addedScreeningTypes,
    includedScreenings,
    recommendationsSection,
    recommendedAddOnsList,
    onRemoveClick,
  } = props;
  const trackEvent = useTrackEvent();

  // Checks if screening is added via the add-ons step
  const currentUser = useUser();

  const hasManageAccountSettings = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  const canOrderCredit = accountHasPermissions(currentUser, [
    'credit_report_setting',
    'credit_report_ready',
  ]);

  const showCreditReportScreening = hasManageAccountSettings || canOrderCredit;

  const onRemoveClickWithTracking = useCallback(
    (screening: AddonsT.ScreeningType) => {
      onRemoveClick(screening);
      trackEvent(
        PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.RECOMMENDATIONS_REMOVED,
        {
          'Section Removed': recommendationsSection
            ? 'Recommended Screenings'
            : 'All Screenings',
        },
      );
    },
    [onRemoveClick, recommendationsSection, trackEvent],
  );

  useEffect(() => {
    if (
      recommendationsSection &&
      recommendedAddOnsList &&
      recommendedAddOnsList?.size > 0
    ) {
      trackEvent(
        PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.RECOMMENDATIONS_VIEWED,
      );
    }
  }, [recommendationsSection]);

  const isAdded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        addedScreeningTypes.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [addedScreeningTypes],
  );

  // Checks if screening is included in the selected package
  const isIncluded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        includedScreenings.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [includedScreenings],
  );

  return (
    <DomesticScreeningsListContainer data-testid='screenings-list-container'>
      <MotorVehicleRecordSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
        onRemoveClick={onRemoveClickWithTracking}
      />
      <CriminalSearchesSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
        onRemoveClick={onRemoveClickWithTracking}
      />
      <VerificationSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
        onRemoveClick={onRemoveClickWithTracking}
      />
      {hasManageAccountSettings && (
        <DrugAndHealthSection
          isAdded={isAdded}
          isIncluded={isIncluded}
          currentUser={currentUser as CurrentUser}
          {...props}
          onRemoveClick={onRemoveClickWithTracking}
        />
      )}
      <CivilSearchesSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
        onRemoveClick={onRemoveClickWithTracking}
      />
      <FACISSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
        onRemoveClick={onRemoveClickWithTracking}
      />
      {showCreditReportScreening && (
        <CreditReportSection
          isAdded={isAdded}
          isIncluded={isIncluded}
          {...props}
          onRemoveClick={onRemoveClickWithTracking}
          status={currentUser?.account?.credit_report_setup}
        />
      )}
    </DomesticScreeningsListContainer>
  );
};

export default DomesticScreeningsList;
