import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { updateParentWindowUrl } from 'utils';
import { M } from '@dashboard-experience/mastodon';
import { Flag, useFlag } from '@dashboard-experience/react-flagr';
import {
  CANDIDATE_PAGE_ORDER_BACKGROUND_CHECK_BUTTON,
  ORDER_BGC_AMPLITUDE_FLAG_KEY,
  ORDER_REDESIGN_KEY,
  ORDER_QUEUING_SUPPORT,
  BETTER_DASHBOARD_ORDER_EXPERIENCE,
} from 'Constants';
import {
  CurrentUser,
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
} from 'components/Packages/Amplitude/analytics';
import { canCreateManualOrders } from 'components/DashboardWrapper/tabs';

const ResponsiveButton = styled(M.Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);

  @media (max-width: 671px) {
    min-height: 28px;
    padding: 6px 10px;
    gap: 6px;
  }

  @media (min-width: 672px) and (max-width: 1055px) {
    min-height: 28px;
    padding: 6px 10px;
    gap: 6px;
  }

  @media (min-width: 1056px) and (max-width: 1310px) {
    height: 36px;
    padding: 8px 14px;
    gap: 8px;
  }

  @media (min-width: 1311px) {
    height: 36px;
    padding: 8px 14px;
    gap: 8px;
  }
`;
type OrderBackgroundCheckButtonProps = {
  currentUser: CurrentUser;
  contextId: string;
  isLimitedPartner?: boolean;
};

const OrderBackgroundCheckButton = ({
  currentUser,
  contextId,
  isLimitedPartner,
}: OrderBackgroundCheckButtonProps) => {
  const [enableOBCButton, setEnableOBCButton] = useState(true);
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const useNewOrderBackgroundFlow =
    useFlag(ORDER_REDESIGN_KEY)?.variantKey === 'enabled';
  const originalFlowFlag =
    useFlag(ORDER_BGC_AMPLITUDE_FLAG_KEY)?.variantKey === 'on';
  const orderQueuingFlag = useFlag(ORDER_QUEUING_SUPPORT)?.variantKey === 'on';
  const betterOrderExperienceFlow = useFlag(BETTER_DASHBOARD_ORDER_EXPERIENCE);
  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  useEffect(() => {
    if (orderQueuingFlag) {
      const isAuthorized = accountHasPermission(currentUser, 'authorized');
      const isFromGoodHire = currentUser?.account?.referrer?.startsWith('gh');
      const isPartner = currentUser?.account?.is_partner_account;
      const isPartnerCustomer =
        currentUser?.account?.partner_account_ids.length > 0;

      if (!isAuthorized && (isFromGoodHire || isPartner || isPartnerCustomer)) {
        setEnableOBCButton(false);
      }
    }
  }, [currentUser, orderQueuingFlag]);

  const handleInvitationsClick = useCallback(() => {
    if (originalFlowFlag && !useNewOrderBackgroundFlow) {
      trackEvent(currentUser, ORDER_BACKGROUND_CHECK_EVENT_NAMES.PAGE_VIEWED, {
        Source: 'Order BGC Button Candidate Page',
      });
    }

    if (!useNewOrderBackgroundFlow) {
      navigate('invitations/new');
    } else {
      const navigationLink =
        betterOrderExperienceFlow?.variantKey === 'on'
          ? 'order-experience/get-started?from=button'
          : 'order/get-started?from=button';

      navigate(navigationLink);
    }
  }, [
    currentUser,
    navigate,
    betterOrderExperienceFlow?.variantKey,
    originalFlowFlag,
    trackEvent,
    useNewOrderBackgroundFlow,
  ]);

  return (
    <Flag
      flagKey={CANDIDATE_PAGE_ORDER_BACKGROUND_CHECK_BUTTON}
      variantKey='enabled'
    >
      <M.Restrict
        authorized={
          canCreateManualOrders(
            hasPermission(currentUser, 'create_manual_orders'),
            hasPermission(currentUser, 'manage_invitations'),
            accountHasPermission(
              currentUser,
              'allow_dashboard_report_ordering',
            ),
          ) && !isLimitedPartner
        }
      >
        {enableOBCButton ? (
          <ResponsiveButton
            id='order-background-check'
            className='invite-candidates'
            kind='primary'
            size='sm'
            onClick={handleInvitationsClick}
          >
            Order new check
          </ResponsiveButton>
        ) : (
          <M.TooltipButton
            label='A saved payment method is required to order background checks. Please contact your Administrator to request a payment method to be added to your company&#39;s Checkr account.'
            id='order-background-check'
            className='disabled invite-candidates'
            align='bottom'
            customStyles={{
              button: { width: 'auto' },
            }}
          >
            Order new background check
          </M.TooltipButton>
        )}
      </M.Restrict>
    </Flag>
  );
};

OrderBackgroundCheckButton.propTypes = {
  currentUser: PropTypes.object.isRequired,
  contextId: PropTypes.string,
  isLimitedPartner: PropTypes.bool,
};

OrderBackgroundCheckButton.defaultProps = {
  contextId: null,
};

export default OrderBackgroundCheckButton;
