import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// FLEXBOX COMPONENT
type FlexProps = {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  columnGap?: '1rem';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
};

// eslint-disable-next-line import/prefer-default-export
export const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  column-gap: ${props => props.columnGap || '0'};
  align-items: ${props => props.alignItems || 'flex-start'};
  vertical-align: ${props => props.verticalAlign || 'middle'};
  width: 100%;
`;

export const StyledFlexPositionRelative = styled(StyledFlex)`
  position: relative;
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const StyledFlexColumn = styled(StyledFlex)`
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1399px) {
    width: 60%;
  }

  @media screen and (min-width: 1400px) {
    width: 100%;
  }
`;

export const StyledCheckbox = styled(M.Checkbox)`
  flex: none;
  width: fit-content;
`;

export const StyledScreeningContainer = styled(StyledFlex)<{
  flexDirection?: string;
}>`
  padding: 0 1rem 1rem 1rem;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};

  :not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid ${colors.brandSlate2};
  }
  :last-child {
    padding-bottom: 0;
  }

  .recommended-screenings-wrap & {
    background-color: ${colors.uiGrey0};
    border-left: 2px solid ${colors.uiAqua500};
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding: 1rem;
  }
`;

export const StyledAdditionalPropContainer = styled(StyledFlex)`
  padding: 0 1rem 1rem 2.375rem;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid ${colors.brandSlate2};
  }
  :last-child {
    padding-bottom: 0;
  }

  .recommended-screenings-wrap & {
    padding-left: 0;
  }
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  color: ${colors.brandNavy4};
`;

export const StyledRecommendationsSectionWrapper = styled.div`
  border-radius: 0.5rem;
  background: linear-gradient(
      180deg,
      transparent 30%,
      rgba(255, 255, 255, 0.5) 60%
    ),
    linear-gradient(90deg, ${colors.uiAqua200}, ${colors.uiAqua100});
  padding: 1.5rem;
  width: 100%;
`;

export const StyledRecommendationsContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledDropdown = styled(M.Dropdown)`
  width: 100%;

  @media screen and (min-width: 1400px) {
    max-width: 530px;
  }

  .recommended-screenings-wrap & {
    max-width: 360px;
  }
`;
