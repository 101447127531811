import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import RoleTile from '../../../Signup/ui/PurchaseRecommendation/RoleTile/RoleTile';

export const StyledScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  max-width: 100%;
  height: 32px;
  margin-top: 24px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledRolesContainer = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const StyledRoleTile = styled(RoleTile)`
  background-color: ${colors.uiAqua100};
  border-color: ${colors.uiAqua300};
  padding-top: 2px;
  padding-bottom: 2px;

  &.ordered,
  &.custom {
    background-color: ${colors.uiAqua50};
  }

  &:hover {
    background-color: ${colors.uiGrey0};
    box-shadow: 0 2px 4px -2px rgba(72, 82, 94, 0.14);
  }

  &:active {
    background-color: ${colors.uiAqua100};
  }

  &.selected {
    background-color: ${colors.uiAqua600};
    color: ${colors.uiGrey0};

    &:hover {
      background-color: ${colors.uiAqua700};
    }

    &:active {
      background-color: ${colors.uiAqua700};
    }
  }
`;
