import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import PurchaseRecommendationModal from './PurchaseRecommendationModal';
import { PACKAGE_RECOMMENDATION_SIGNUP } from '../../../../Flags';

const OnboardingModal = () => {
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const [modalOpen, setModalOpen] = useState(false);
  const showInitialOrderRecommendationModal =
    useFlag(PACKAGE_RECOMMENDATION_SIGNUP)?.variantKey === 'on';
  const isAdmin = currentUser.roles?.includes('admin');
  const hasOrdered = currentUser?.account?.has_report_or_invitation;

  const industry_id =
    currentUser?.account?.industry_id ??
    Number(localStorage.getItem('industry_id'));

  useEffect(() => {
    // Only open the purchase recommendation modal if the feature flag is enabled
    // and the account is not tied to an industry_id
    if (showInitialOrderRecommendationModal && !industry_id && isAdmin) {
      setModalOpen(true);
    }
  }, [showInitialOrderRecommendationModal, currentUser, industry_id, isAdmin]);

  const closeModal = useCallback(
    (step?: string) => {
      if (step) {
        trackEvent(
          PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ONBOARDING_EXITED,
          {
            'Exit Page': step,
          },
        );
      }
      setModalOpen(false);
    },
    [trackEvent],
  );

  return (
    <PurchaseRecommendationModal
      modalOpen={modalOpen}
      closeModal={closeModal}
      hasOrdered={hasOrdered}
    />
  );
};

export default OnboardingModal;
