import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  useTrackEvent,
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
} from 'utils/analytics';
import { Card } from './RoleTile.styles';

interface Props {
  role: string;
  onClick: (role: string) => void;
  selected?: boolean;
  removable?: boolean;
  addable?: boolean;
  isCustom?: boolean;
  className?: string;
}

const RoleTile: React.FC<Props> = ({
  role,
  selected,
  onClick,
  removable,
  addable,
  isCustom,
  className,
}) => {
  const trackEvent = useTrackEvent();

  const handleClick = useCallback(() => {
    if (isCustom) {
      trackEvent(
        PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_CUSTOM_USED,
      );
    }
    onClick(role);
  }, [isCustom, onClick, role, trackEvent]);

  const tileClassNames = () => {
    const classes = [];
    if (selected) classes.push('selected');
    if (removable) classes.push('removable');
    return classes.join(' ');
  };

  return (
    <Card
      data-testid={`role-tile-${removable ? 'remove' : 'add'}-${role}`}
      className={`role-tile ${tileClassNames()} ${className}`}
      onClick={handleClick}
    >
      {addable && (
        <M.Icon
          data-testid='role-tile-add'
          icon='Add'
          size={24}
          style={{ marginRight: '8px' }}
        />
      )}
      <>{role}</>
      {removable && (
        <M.Icon
          data-testid='role-tile-remove'
          icon='Close'
          size={24}
          style={{ marginLeft: '8px' }}
        />
      )}
    </Card>
  );
};

export default RoleTile;
