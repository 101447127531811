import { useMutation } from 'react-query';
import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import useGetUser from './useGetUser';

const patchJobRole = async (
  accountId: string,
  jobRoleId: string,
  jobRole: { name: string; attribute_ids: number[] },
) => {
  const token = localStorage.getItem('accessToken');

  const response = await axios.patch(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/job_roles/${jobRoleId}`,
    jobRole,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

interface PatchJobRoleAttributes {
  jobRole: { name: string; attribute_ids: number[] };
  jobRoleId: string;
}

interface PatchJobRoleOptions {
  onSuccess?: (response: JobRoleResponse) => void;
}

function usePatchJobRole(options?: PatchJobRoleOptions) {
  const { data: user } = useGetUser();
  const [mutate, { data, isLoading, error }] = useMutation(
    async ({ jobRole, jobRoleId }: PatchJobRoleAttributes) => {
      if (!user) throw new Error('User information is not available');

      return patchJobRole(user.account.id, jobRoleId, jobRole);
    },
    {
      onSuccess: options?.onSuccess,
      onError: error => {},
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default usePatchJobRole;

export interface JobRoleResponse {
  id: string;
  name: string;
  account_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  attribute_ids: number[];
}
