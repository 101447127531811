import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import gavel from 'components/AddScreenings/shared/svg/gavel.svg';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import CountyCivilSearch from './CountyCivilSearch';
import FederalCivilSearch from './FederalCivilSearch';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  recommendedAddOnsList?: Set<string>;
};

const CivilSearchesSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
  recommendedAddOnsList,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.civilSearches',
  });
  const screeningSectionId = 'civil-searches';

  const isCountyCivilSearchIncluded = isIncluded(
    ScreeningProductType.COUNTY_CIVIL_SEARCH,
  );

  const isFederalCivilSearchIncluded = isIncluded(
    ScreeningProductType.FEDERAL_CIVIL_SEARCH,
  );

  const countyCivilSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.COUNTY_CIVIL_SEARCH,
  );

  const shouldRender = (addOn: string) => {
    return !recommendedAddOnsList || recommendedAddOnsList.has(addOn);
  };

  return (
    <ScreeningSection
      id={screeningSectionId}
      name={t('sectionName')}
      icon={gavel}
      recommendedAddOnsList={recommendedAddOnsList}
      screeningSectionId={screeningSectionId}
    >
      <StyledFlex flexDirection='column'>
        {shouldRender(ScreeningProductType.COUNTY_CIVIL_SEARCH) && (
          <CountyCivilSearch
            id={ScreeningProductType.COUNTY_CIVIL_SEARCH as string}
            price={countyCivilSearchPrice}
            isAdded={isAdded(ScreeningProductType.COUNTY_CIVIL_SEARCH)}
            isIncluded={isCountyCivilSearchIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {shouldRender(ScreeningProductType.FEDERAL_CIVIL_SEARCH) && (
          <FederalCivilSearch
            id={ScreeningProductType.FEDERAL_CIVIL_SEARCH as string}
            price={countyCivilSearchPrice}
            isAdded={isAdded(ScreeningProductType.FEDERAL_CIVIL_SEARCH)}
            isIncluded={isFederalCivilSearchIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
      </StyledFlex>
    </ScreeningSection>
  );
};

export default CivilSearchesSection;
