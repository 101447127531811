import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FORCE_HAMBURGER_NAV_BREAKPOINT } from 'modules/layout/navigation/constants';
import { namespace } from 'modules/reports-list/locales';
import { ORDER_REDESIGN_KEY } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import styled from 'styled-components';
import ManualOrderButton from 'components/Search/ManualOrderButton';
import { updateParentWindowUrl } from 'utils';
import {
  CurrentUser,
  getParamFromUrl,
  getStatusTypeForUser,
  StatusTypes,
} from '@dashboard-experience/utils';
import { useHistory } from 'react-router-dom';
import { ReportsTitle } from './reports-title';
import OrderBackgroundCheckButton from './order-background-check-button';

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 32px;
  width: 100%;
  border-bottom: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  background: var(--ui-bg-primary-grey-0, #fff);
  box-shadow: 0px 4px 4px -3px rgba(72, 82, 94, 0.1);

  @media (max-width: ${FORCE_HAMBURGER_NAV_BREAKPOINT}px) {
    padding: 16px 24px;
  }
`;

const HeadingButtonsContainer = styled.div`
  display: inline-flex;
  margin-left: auto;

  .mastodon-button.secondary {
    margin-right: 1rem !important;
  }

  #mastodon & {
    .mastodon-button {
      max-width: fit-content;
      height: fit-content;

      &.primary {
        margin: 0;

        &:active,
        &:focus,
        &:hover {
        }
      }
    }
  }
`;

type Props = {
  currentUser: CurrentUser;
};

const Header: React.FC<Props> = ({ currentUser }) => {
  const userType = getStatusTypeForUser(currentUser);

  const history = useHistory();

  const { t } = useTranslation();
  const headerTitle = t(`${namespace}:header.title`);
  const orderRedesignKey = useFlag(ORDER_REDESIGN_KEY);
  const isLimitedPartner = userType === StatusTypes.DSP;
  const contextId = getParamFromUrl(window, 'contextId') || undefined;

  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  return (
    <StickyHeader data-testid='reports-list-header'>
      <HeadingContainer data-testid='search-page-heading-container'>
        <ReportsTitle style={{ marginBottom: 0 }} title={headerTitle} />
        <HeadingButtonsContainer className='btn-container'>
          {orderRedesignKey?.variantKey !== 'enabled' && (
            <ManualOrderButton currentUser={currentUser} navigate={navigate} />
          )}
          <OrderBackgroundCheckButton
            currentUser={currentUser}
            contextId={contextId}
            isLimitedPartner={isLimitedPartner}
          />
        </HeadingButtonsContainer>
      </HeadingContainer>
    </StickyHeader>
  );
};

export default Header;
