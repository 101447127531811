import {
  HAMBURGER_NAV_HEADER_HEIGHT,
  NAV_COLLAPSE_TRANSITION,
  NAV_SIZES,
} from 'modules/layout/navigation/constants';
import React from 'react';
import styled, { css } from 'styled-components';

type BodyContainerProps = {
  reportsListRedesignState: boolean;
  isHamburgerNavigationTrue: boolean;
};

type MainContentProps = {
  isHamburgerNavigationTrue: boolean;
};

export const BodyContainer = styled.div<BodyContainerProps>`
  margin-right: auto;
  margin-left: auto;
  margin-top: ${({ reportsListRedesignState, isHamburgerNavigationTrue }) =>
    reportsListRedesignState && isHamburgerNavigationTrue ? '56px' : '0'};
  padding-left: 15px;
  padding-right: 15px;
  overflow: visible;
  width: 100%;
`;

export const MainContent = styled.div<MainContentProps>`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  margin-top: 0;

  overflow-y: auto;
  overflow-x: clip;

  height: ${({ isHamburgerNavigationTrue }) =>
    `calc(100vh - ${
      isHamburgerNavigationTrue ? HAMBURGER_NAV_HEADER_HEIGHT : 0
    }px)`};

  @media (min-width: 768px) {
    align-items: stretch;
  }
`;

const getPadding = (removePadding: boolean) => {
  if (removePadding) {
    return '0';
  }
  return '4.5rem 1.5rem 1rem';
};

const getMediaQueryPadding = (removePadding: boolean) => {
  if (removePadding) {
    return '0';
  }
  return '3rem 4rem';
};

type ContentNewProps = {
  removePadding: boolean;
};

export const Content = styled.div<ContentNewProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: ${({ removePadding }) => getPadding(removePadding)};

  @media (min-width: 768px) {
    padding: ${({ removePadding }) => getMediaQueryPadding(removePadding)};
  }
`;

export const FlexGrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

type ContentWrapNewProps = {
  navCollapsed: boolean;
};

export const ContentWrap = styled.div<ContentWrapNewProps>`
  position: relative;
  left: 0;
  transition: left 0.35s ease;

  display: flex;
  flex-direction: column;

  transition: width ${NAV_COLLAPSE_TRANSITION};
  width: calc(100% - ${NAV_SIZES.collapsed});

  @media (min-width: 768px) {
    ${({ navCollapsed }) =>
      !navCollapsed &&
      css`
        width: calc(100% - ${NAV_SIZES.expanded});
      `}
  }
`;

export const ContentWrapReportsListRedesign = styled.div`
  position: relative;
  left: 0;
  transition: left 0.35s ease;

  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type BodyProps = {
  children: React.ReactNode;
  reportsListRedesignState: boolean;
  isHamburgerNavigationTrue: boolean;
};

export const Body: React.FC<BodyProps> = ({
  children,
  reportsListRedesignState,
  isHamburgerNavigationTrue,
}) => {
  return (
    <BodyContainer
      reportsListRedesignState={reportsListRedesignState}
      isHamburgerNavigationTrue={isHamburgerNavigationTrue}
    >
      {children}
    </BodyContainer>
  );
};
