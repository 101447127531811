import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation, Trans } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import {
  Divider,
  StyledH2,
  StyledNote,
  StyledSSLSideTitle,
  GridCol,
} from './PaymentStyledComponents';
import CustomCol from './CustomPaymentComponents';

const PaymentSectionHeader: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const sectionDescription = currentUser?.account?.account_hierarchy_billing
    ? `payment.payment_section_header.description_ahi`
    : `payment.payment_section_header.description`;

  return (
    <M.Grid>
      <M.GridRow>
        <CustomCol col={5} noPadding style={{ marginTop: '3px' }}>
          <M.GridRow>
            <GridCol>
              <StyledH2 className='mb-0'>
                {t(`payment.payment_section_header.title`)}

                <StyledSSLSideTitle>
                  <svg
                    width='15'
                    height='15'
                    viewBox='0 0 15 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11.25 6.5625H10.3125V3.75C10.3125 2.1967 9.0533 0.9375 7.5 0.9375C5.9467 0.9375 4.6875 2.1967 4.6875 3.75V6.5625H3.75C3.23223 6.5625 2.8125 6.98223 2.8125 7.5V13.125C2.8125 13.6428 3.23223 14.0625 3.75 14.0625H11.25C11.7678 14.0625 12.1875 13.6428 12.1875 13.125V7.5C12.1875 6.98223 11.7678 6.5625 11.25 6.5625ZM5.625 3.75C5.625 2.71447 6.46447 1.875 7.5 1.875C8.53553 1.875 9.375 2.71447 9.375 3.75V6.5625H5.625V3.75ZM11.25 13.125H3.75V7.5H11.25V13.125Z'
                      fill='#576C82'
                    />
                    <path
                      d='M10.3125 6.5625H10.0625V6.8125H10.3125V6.5625ZM4.6875 6.5625V6.8125H4.9375V6.5625H4.6875ZM9.375 6.5625V6.8125H9.625V6.5625H9.375ZM5.625 6.5625H5.375V6.8125H5.625V6.5625ZM11.25 13.125V13.375H11.5V13.125H11.25ZM3.75 13.125H3.5V13.375H3.75V13.125ZM3.75 7.5V7.25H3.5V7.5H3.75ZM11.25 7.5H11.5V7.25H11.25V7.5ZM11.25 6.3125H10.3125V6.8125H11.25V6.3125ZM10.5625 6.5625V3.75H10.0625V6.5625H10.5625ZM10.5625 3.75C10.5625 2.05863 9.19137 0.6875 7.5 0.6875V1.1875C8.91523 1.1875 10.0625 2.33477 10.0625 3.75H10.5625ZM7.5 0.6875C5.80863 0.6875 4.4375 2.05863 4.4375 3.75H4.9375C4.9375 2.33477 6.08477 1.1875 7.5 1.1875V0.6875ZM4.4375 3.75V6.5625H4.9375V3.75H4.4375ZM4.6875 6.3125H3.75V6.8125H4.6875V6.3125ZM3.75 6.3125C3.09416 6.3125 2.5625 6.84416 2.5625 7.5H3.0625C3.0625 7.1203 3.3703 6.8125 3.75 6.8125V6.3125ZM2.5625 7.5V13.125H3.0625V7.5H2.5625ZM2.5625 13.125C2.5625 13.7808 3.09416 14.3125 3.75 14.3125V13.8125C3.3703 13.8125 3.0625 13.5047 3.0625 13.125H2.5625ZM3.75 14.3125H11.25V13.8125H3.75V14.3125ZM11.25 14.3125C11.9058 14.3125 12.4375 13.7808 12.4375 13.125H11.9375C11.9375 13.5047 11.6297 13.8125 11.25 13.8125V14.3125ZM12.4375 13.125V7.5H11.9375V13.125H12.4375ZM12.4375 7.5C12.4375 6.84416 11.9058 6.3125 11.25 6.3125V6.8125C11.6297 6.8125 11.9375 7.1203 11.9375 7.5H12.4375ZM5.875 3.75C5.875 2.85254 6.60254 2.125 7.5 2.125V1.625C6.32639 1.625 5.375 2.57639 5.375 3.75H5.875ZM7.5 2.125C8.39746 2.125 9.125 2.85254 9.125 3.75H9.625C9.625 2.57639 8.6736 1.625 7.5 1.625V2.125ZM9.125 3.75V6.5625H9.625V3.75H9.125ZM9.375 6.3125H5.625V6.8125H9.375V6.3125ZM5.875 6.5625V3.75H5.375V6.5625H5.875ZM11.25 12.875H3.75V13.375H11.25V12.875ZM4 13.125V7.5H3.5V13.125H4ZM3.75 7.75H11.25V7.25H3.75V7.75ZM11 7.5V13.125H11.5V7.5H11Z'
                      fill='#576C82'
                    />
                    <mask
                      id='mask0_1018_11493'
                      maskUnits='userSpaceOnUse'
                      x='2'
                      y='0'
                      width='11'
                      height='15'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.25 6.5625H10.3125V3.75C10.3125 2.1967 9.0533 0.9375 7.5 0.9375C5.9467 0.9375 4.6875 2.1967 4.6875 3.75V6.5625H3.75C3.23223 6.5625 2.8125 6.98223 2.8125 7.5V13.125C2.8125 13.6428 3.23223 14.0625 3.75 14.0625H11.25C11.7678 14.0625 12.1875 13.6428 12.1875 13.125V7.5C12.1875 6.98223 11.7678 6.5625 11.25 6.5625ZM5.625 3.75C5.625 2.71447 6.46447 1.875 7.5 1.875C8.53553 1.875 9.375 2.71447 9.375 3.75V6.5625H5.625V3.75ZM11.25 13.125H3.75V7.5H11.25V13.125Z'
                        fill='white'
                      />
                      <path
                        d='M10.3125 6.5625H10.0625V6.8125H10.3125V6.5625ZM4.6875 6.5625V6.8125H4.9375V6.5625H4.6875ZM9.375 6.5625V6.8125H9.625V6.5625H9.375ZM5.625 6.5625H5.375V6.8125H5.625V6.5625ZM11.25 13.125V13.375H11.5V13.125H11.25ZM3.75 13.125H3.5V13.375H3.75V13.125ZM3.75 7.5V7.25H3.5V7.5H3.75ZM11.25 7.5H11.5V7.25H11.25V7.5ZM11.25 6.3125H10.3125V6.8125H11.25V6.3125ZM10.5625 6.5625V3.75H10.0625V6.5625H10.5625ZM10.5625 3.75C10.5625 2.05863 9.19137 0.6875 7.5 0.6875V1.1875C8.91523 1.1875 10.0625 2.33477 10.0625 3.75H10.5625ZM7.5 0.6875C5.80863 0.6875 4.4375 2.05863 4.4375 3.75H4.9375C4.9375 2.33477 6.08477 1.1875 7.5 1.1875V0.6875ZM4.4375 3.75V6.5625H4.9375V3.75H4.4375ZM4.6875 6.3125H3.75V6.8125H4.6875V6.3125ZM3.75 6.3125C3.09416 6.3125 2.5625 6.84416 2.5625 7.5H3.0625C3.0625 7.1203 3.3703 6.8125 3.75 6.8125V6.3125ZM2.5625 7.5V13.125H3.0625V7.5H2.5625ZM2.5625 13.125C2.5625 13.7808 3.09416 14.3125 3.75 14.3125V13.8125C3.3703 13.8125 3.0625 13.5047 3.0625 13.125H2.5625ZM3.75 14.3125H11.25V13.8125H3.75V14.3125ZM11.25 14.3125C11.9058 14.3125 12.4375 13.7808 12.4375 13.125H11.9375C11.9375 13.5047 11.6297 13.8125 11.25 13.8125V14.3125ZM12.4375 13.125V7.5H11.9375V13.125H12.4375ZM12.4375 7.5C12.4375 6.84416 11.9058 6.3125 11.25 6.3125V6.8125C11.6297 6.8125 11.9375 7.1203 11.9375 7.5H12.4375ZM5.875 3.75C5.875 2.85254 6.60254 2.125 7.5 2.125V1.625C6.32639 1.625 5.375 2.57639 5.375 3.75H5.875ZM7.5 2.125C8.39746 2.125 9.125 2.85254 9.125 3.75H9.625C9.625 2.57639 8.6736 1.625 7.5 1.625V2.125ZM9.125 3.75V6.5625H9.625V3.75H9.125ZM9.375 6.3125H5.625V6.8125H9.375V6.3125ZM5.875 6.5625V3.75H5.375V6.5625H5.875ZM11.25 12.875H3.75V13.375H11.25V12.875ZM4 13.125V7.5H3.5V13.125H4ZM3.75 7.75H11.25V7.25H3.75V7.75ZM11 7.5V13.125H11.5V7.5H11Z'
                        fill='white'
                      />
                    </mask>
                    <g mask='url(#mask0_1018_11493)' />
                  </svg>
                  <div>{t(`payment.payment_section_header.side_title`)}</div>
                </StyledSSLSideTitle>
              </StyledH2>
              <StyledNote className='mb-0'>
                <Trans>{t(sectionDescription)}</Trans>
              </StyledNote>
            </GridCol>
          </M.GridRow>
          <Divider />
        </CustomCol>
      </M.GridRow>
    </M.Grid>
  );
};

export default PaymentSectionHeader;
