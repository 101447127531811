import React from 'react';
import { useSearchState } from '../state/hooks';

import ViewContent from './view-content';

type Props = {};

const SearchTableContainer: React.FC<Props> = () => {
  const state = useSearchState();
  const { view } = state;
  const page = view?.filters.page;

  return (
    <div data-testid='reports-list-search-table'>
      {!!view && !!page && <ViewContent view={view} page={page} />}
    </div>
  );
};

export default SearchTableContainer;
