import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import { namespace } from 'components/BetterOrderExperience/locales';
import {
  StyledAdditionalPropContainer,
  StyledFlex,
  StyledScreeningContainer,
} from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningProductType } from '../../AddonsStep.enums';
import type { AddonsT } from '../..';
import OutOfCountryHistory from './OutOfCountryHistory';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  screeningSectionId: string;
  recommendedAddOnsList?: Set<string>;
  sectionName: string;
};

const EducationVerification: React.FC<Props> = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  screeningSectionId,
  recommendedAddOnsList,
  sectionName,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.verificationSection.educationVerification',
  });

  const [outOfCountryHistoryChecked, setOutOfCountryHistoryChecked] =
    useState(false);

  const handleAddClick = useCallback(() => {
    onAddClick(ScreeningProductType.EDUCATION_VERIFICATION, price);
  }, [onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.EDUCATION_VERIFICATION);
  }, [onRemoveClick]);

  const handleOutOfCountryHistoryChange = useCallback(
    e => {
      const { checked } = e.target;
      setOutOfCountryHistoryChecked(checked);

      const additionalProperties = {
        education_verification: {
          international_upgrade: checked,
        },
      };

      onAddClick(
        ScreeningProductType.EDUCATION_VERIFICATION,
        price,
        additionalProperties,
      );
    },
    [onAddClick, price],
  );

  return (
    <StyledScreeningContainer flexDirection='column'>
      <StyledFlex>
        <ScreeningSectionItem
          id={id}
          description={t('description')}
          advantages={t('advantages', { returnObjects: true })}
          disadvantages={t('considerations', {
            returnObjects: true,
          })}
          price={price}
          screeningName={t('name')}
          recommendedAddOnsList={recommendedAddOnsList}
          screeningSectionId={screeningSectionId}
          sectionName={sectionName}
        />
        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            added={isAdded}
            disabled={false}
            included={isIncluded}
          />
        </AddButtonContainer>
      </StyledFlex>

      {!isIncluded && (
        <StyledAdditionalPropContainer>
          <OutOfCountryHistory
            onChange={handleOutOfCountryHistoryChange}
            checked={outOfCountryHistoryChecked}
            screeningType={ScreeningProductType.EDUCATION_VERIFICATION}
          />
        </StyledAdditionalPropContainer>
      )}
    </StyledScreeningContainer>
  );
};

export default EducationVerification;
