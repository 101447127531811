import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';

const getRecommendedAddOns = async (
  attribute_ids: number[],
  industry_id?: number[],
) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(
    `${CHECKR_API_BASE_URL}/recommended_add_ons`,
    {
      params: {
        attribute_ids: attribute_ids?.join(','),
        ...(industry_id && { industry_id }),
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      paramsSerializer: params => {
        return new URLSearchParams(params).toString();
      },
    },
  );

  return response.data;
};

export const useGetRecommendedAddOns = (
  attribute_ids: number[],
  industry_id?: number[],
) => {
  const request = () => getRecommendedAddOns(attribute_ids, industry_id);

  const [call, result] = useMutation(request);
  const { isLoading, data } = result;

  return {
    call,
    result,
    isLoading,
    data,
  };
};

export default useGetRecommendedAddOns;
