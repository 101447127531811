import React, { ReactNode, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import ScreeningSectionItemBulletedList from './ScreeningSectionItemBulletedList';
import { StyledFlex } from '../../AddonsStep.styles';
import {
  StyledDescriptionBox,
  StyledExpandableTitle,
  StyledExpandTitle,
  StyledPrice,
  StyledRecommendedPriceBox,
  StyledRecommendedScreeningBox,
  StyledScreeningInfo,
  StyledRecommendedTitle,
  StyledRecommendedSubTitle,
} from './ScreeningSectionItem.styles';
import { namespace } from '../../../locales';

type Props = {
  id: string;
  screeningName: string;
  screeningSubName?: string;
  description: ReactNode;
  advantages: string[];
  disadvantages: string[];
  price: string | ReactNode;
  initialExpanded?: boolean;
  titleTag?: ReactNode;
  screeningSectionId?: string;
  recommendedAddOnsList?: Set<string>;
  sectionName?: string;
};

const ScreeningSectionItem: React.FC<Props> = ({
  id,
  screeningName,
  description,
  advantages,
  disadvantages,
  price,
  initialExpanded = false,
  titleTag,
  screeningSectionId,
  recommendedAddOnsList,
  sectionName,
  screeningSubName,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns',
  });
  const handleDetailsClick = useCallback(() => {
    const accordionHeading = document.querySelector(
      `.${screeningSectionId} .cds--accordion__item`,
    ) as HTMLButtonElement;

    accordionHeading.scrollIntoView({ behavior: 'smooth' });
    accordionHeading.classList.add('highlighted');
    setTimeout(() => {
      accordionHeading.classList.remove('highlighted');
    }, 5000);
  }, [screeningSectionId]);

  return (
    <>
      {!recommendedAddOnsList ? (
        <StyledExpandableTitle
          initialExpanded={initialExpanded}
          title={
            <StyledFlex justifyContent='space-between' alignItems='center'>
              <StyledExpandTitle data-testid={`${id}-screening-name`}>
                <StyledFlex alignItems='center'>
                  {screeningName}
                  {titleTag && <span>{titleTag}</span>}
                </StyledFlex>
              </StyledExpandTitle>
              <StyledPrice data-testid={`${id}-price`}>{price}</StyledPrice>
            </StyledFlex>
          }
        >
          <StyledScreeningInfo className={`${id}-description-text`}>
            {description}
          </StyledScreeningInfo>
          <StyledFlex columnGap='1rem'>
            <StyledDescriptionBox className={`${id}-advantages`}>
              <ScreeningSectionItemBulletedList
                header='Advantages'
                items={advantages}
              />
            </StyledDescriptionBox>

            <StyledDescriptionBox className={`${id}-disadvantages`}>
              <ScreeningSectionItemBulletedList
                header='Considerations'
                items={disadvantages}
              />
            </StyledDescriptionBox>
          </StyledFlex>
        </StyledExpandableTitle>
      ) : (
        <StyledFlex justifyContent='space-between' alignItems='flex-start'>
          <StyledRecommendedScreeningBox style={{ width: '100%' }}>
            <StyledRecommendedTitle data-testid={`${id}-screening-name`}>
              {screeningName}
              {titleTag && <span>{titleTag}</span>}
            </StyledRecommendedTitle>
            {screeningSubName && (
              <StyledRecommendedSubTitle>
                {screeningSubName}
              </StyledRecommendedSubTitle>
            )}
          </StyledRecommendedScreeningBox>
          <StyledRecommendedPriceBox>
            <StyledPrice data-testid={`${id}-price`}>{price}</StyledPrice>
            <M.TooltipDefinition
              definition={`${t(
                'recommendationsSection.detailsTooltipStart',
              )} ${sectionName} ${t(
                'recommendationsSection.detailsTooltipEnd',
              )}`}
              align='top'
              highlighted={false}
            >
              <M.Link onClick={handleDetailsClick}>Details</M.Link>
            </M.TooltipDefinition>
          </StyledRecommendedPriceBox>
        </StyledFlex>
      )}
    </>
  );
};

export default ScreeningSectionItem;
