import React, { useCallback } from 'react';
import moment from 'moment/moment';
import { Report } from '@dashboard-experience/utils';
import PdfLink from 'modules/candidate/ui/header/report-actions/v2/download-actions/pdf-link';
import { Document, GenericObject } from 'types';
import { getInternationalReportPdfButtonText } from 'utils';

const PDF_MAPPER: GenericObject = {
  pdfReport: {
    text: 'Report PDF',
    docType: 'report',
    canDisable: true,
    rank: 1,
  },
  pdfReportEncrypted: {
    text: 'Report PDF',
    docType: 'report_encrypted',
    canDisable: true,
    rank: 1,
  },
  pdfHealthReport: {
    text: 'Health Screening PDF',
    docType: 'health_report',
    canDisable: true,
    rank: 2,
  },
  pdfWisconsinDoj: {
    text: 'WI DOJ PDF',
    docType: 'wisconsin_doj',
    canDisable: true,
    rank: 3,
  },
  pdfStateCriminalSearch: {
    text: 'State Criminal PDF',
    docType: 'state_criminal_search',
    canDisable: true,
    rank: 4,
  },
  pdfCreditReport: {
    text: 'Credit Report PDF',
    docType: 'credit_report',
    rank: 5,
  },
  pdfSelfDisclosure: {
    text: 'Self Disclosure PDF',
    docType: 'self_disclosure',
    rank: 6,
  },
  dotEmploymentAuthorization: {
    text: 'DOT Employment Authorizations',
    docType: 'dot_emplooyment_authorization',
    canDisable: true,
    rank: 7,
  },
  pdfInternationalReport: {
    text: 'International Report PDF',
    docType: 'international_report',
    canDisable: true,
    rank: 8,
  },
  paChildAbuseResultCertificate: {
    text: 'PA State Registry Certificate',
    docType: 'pdf_pa_child_abuse_certificate',
    rank: 9,
  },
  pdfDrugAlcoholClearinghouseReport: {
    text: 'Drug and Alcohol Clearinghouse Report PDF',
    docType: 'pdf_drug_alcohol_clearinghouse_report',
    canDisable: true,
    rank: 10,
  },
  pdfProfessionalLicenseVerificationReport: {
    text: 'Professional License Report PDF',
    docType: 'professional_license_verification_report',
    canDisable: true,
    rank: 11,
  },
  orderSummaryPdf: {
    text: 'Order Summary PDF',
    docType: 'order_summary_pdf',
    canDisable: true,
    rank: 9001,
  },
  cdlisReport: {
    text: 'CDLIS Report PDF',
    docType: 'cdlis_report',
    canDisable: true,
    rank: 12,
  },
};

type PdfItemProps = {
  report: Report;
  document: Document;
  pdfType: string;
  closeFn: Function;
};

function formatTime(created_at: string) {
  return moment(created_at).format('YYYY/MM/DD hh:mmA');
}
const PdfItem = ({ report, document, pdfType, closeFn }: PdfItemProps) => {
  const getText = useCallback(
    (text: string) => {
      if (pdfType === 'pdfInternationalReport') {
        return getInternationalReportPdfButtonText(report);
      }
      return text;
    },
    [pdfType, report],
  );

  const getItem = useCallback(() => {
    // @ts-ignore
    const { docType, canDisable, text } = PDF_MAPPER[pdfType];
    let item: GenericObject = {
      link: document.download_uri,
      text: getText(text),
      docType,
    };

    if (canDisable) {
      item = { disabled: report.status === 'pending', ...item };
    }
    if (['pdfReport', 'pdfReportEncrypted'].includes(pdfType)) {
      item = { type: document.type, isPdfReport: true, ...item };
    }
    if (document.historical === true) {
      item = { historical: true, ...item };
    }

    return item;
  }, [document, pdfType, report.status, getText]);

  const pdf = getItem();
  const key = `${pdf.docType}-${document.id}`;
  const customDescription =
    document.historical === true
      ? `Produced on ${formatTime(document.created_at)}`
      : undefined;

  return (
    <PdfLink
      key={key}
      item={pdf}
      // @ts-ignore TODO: this error should be fixed once report type is updated in Utils
      report={report}
      closeWithAction={closeFn}
      showPdfEncryptionNote={document.type === 'pdf_report_encrypted'}
      customDescription={customDescription}
    />
  );
};

export default PdfItem;
