import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import certificate from 'components/AddScreenings/shared/svg/certificate.svg';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import EmploymentVerification from './EmploymentVerification';
import EducationVerification from './EducationVerification';
import ProfessionalLicenseVerification from './ProfessionalLicenseVerification';
import PersonalReferenceVerification from './PersonalReferenceVerification';
import ProfessionalReferenceVerification from './ProfessionalReferenceVerification';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  recommendedAddOnsList?: Set<string>;
};

const VerificationSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
  selectedAdditionalProperties,
  recommendedAddOnsList,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.verificationSection',
  });
  const screeningSectionId = 'verifications';

  const isEmploymentVerifiIncluded = isIncluded(
    ScreeningProductType.EMPLOYMENT_VERIFICATION,
  );

  const isEducationVerifIncluded = isIncluded(
    ScreeningProductType.EDUCATION_VERIFICATION,
  );
  const isProfessionalLicenseVerifIncluded = isIncluded(
    ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION,
  );
  const isPersonalReferenceVerifIncluded = isIncluded(
    ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION,
  );
  const isProfessionalReferenceVerifIncluded = isIncluded(
    ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
  );

  const educationVerificationPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.EDUCATION_VERIFICATION,
  );

  const professionalLicenseVerificiationPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION,
  );

  const shouldRender = (addOn: string) => {
    return !recommendedAddOnsList || recommendedAddOnsList.has(addOn);
  };

  return (
    <ScreeningSection
      id={screeningSectionId}
      name={t('sectionName')}
      icon={certificate}
      recommendedAddOnsList={recommendedAddOnsList}
      screeningSectionId={screeningSectionId}
    >
      <StyledFlex flexDirection='column'>
        {shouldRender(ScreeningProductType.EMPLOYMENT_VERIFICATION) && (
          <EmploymentVerification
            id={ScreeningProductType.EMPLOYMENT_VERIFICATION}
            addOnPrices={addOnPrices}
            isAdded={isAdded(ScreeningProductType.EMPLOYMENT_VERIFICATION)}
            isIncluded={isEmploymentVerifiIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            selectedAdditionalProperties={
              selectedAdditionalProperties?.employment_verification
            }
            screeningSectionId={screeningSectionId}
            recommendedAddOnsList={recommendedAddOnsList}
            sectionName={t('sectionName')}
          />
        )}
        {shouldRender(ScreeningProductType.EDUCATION_VERIFICATION) && (
          <EducationVerification
            id={ScreeningProductType.EDUCATION_VERIFICATION}
            price={educationVerificationPrice}
            isAdded={isAdded(ScreeningProductType.EDUCATION_VERIFICATION)}
            isIncluded={isEducationVerifIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {shouldRender(
          ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION,
        ) && (
          <ProfessionalLicenseVerification
            id={ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION}
            price={professionalLicenseVerificiationPrice}
            isAdded={isAdded(
              ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION,
            )}
            isIncluded={isProfessionalLicenseVerifIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {shouldRender(ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION) && (
          <PersonalReferenceVerification
            id={ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION}
            isAdded={isAdded(
              ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION,
            )}
            isIncluded={isPersonalReferenceVerifIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            addOnPrices={addOnPrices}
            selectedAdditionalProperties={
              selectedAdditionalProperties?.personal_reference_verification
            }
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {shouldRender(
          ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
        ) && (
          <ProfessionalReferenceVerification
            id={ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION}
            addOnPrices={addOnPrices}
            isAdded={isAdded(
              ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
            )}
            isIncluded={isProfessionalReferenceVerifIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            selectedAdditionalProperties={
              selectedAdditionalProperties?.professional_reference_verification
            }
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
      </StyledFlex>
    </ScreeningSection>
  );
};

export default VerificationSection;
