import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import {
  PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import type { GetStartedT } from 'components/BetterOrderExperience';
import RoleDefinitionModal from './RoleDefinitionModal';
import {
  StyledHeaderSection,
  StyledSubTitle,
  StyledQuestion,
} from '../../../Signup/ui/PurchaseRecommendation/PurchaseRecommendationModal.styles';
import {
  StyledRoleTile,
  StyledRolesContainer,
  StyledScrollWrapper,
} from './RecommendationsSection.styles';
import { namespace } from '../../locales';
import useGetAccountRoles from '../../../Signup/hooks/useGetAccountRoles';
import { useUser } from '../../../../context/CurrentUser';
import BetterOrderExperienceContext from '../../../../pages/BetterOrderExperience/context';
import { AccountRoleStatus } from '../../GetStartedStep/GetStartedStep.types';

type RecommendationsSectionProps = {
  recommendedAddOnsList: Set<string>;
  setSelectedRolesAttr: Dispatch<SetStateAction<number[]>>;
  selectedRole: { name: string; id: string };
  setSelectedRole: Dispatch<SetStateAction<{ name: string; id: string }>>;
};

const RecommendationsSection = ({
  recommendedAddOnsList,
  setSelectedRolesAttr,
  selectedRole,
  setSelectedRole,
}: RecommendationsSectionProps) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.recommendationsSection',
  });
  const { account } = useUser();
  const trackEvent = useTrackEvent();
  const { invites } = useContext(BetterOrderExperienceContext);
  const { data: rolesResponse, refetch } = useGetAccountRoles(account.id, true);
  const [markedRoles, setMarkedRoles] = useState<AccountRoleStatus[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const roles: GetStartedT.AccountRole[] = useMemo(() => {
    return rolesResponse?.job_roles ?? [];
  }, [rolesResponse?.job_roles]);

  const inviteRoles = useMemo(
    () => Array.from(new Set(invites.map(invite => invite.role))),
    [invites],
  );

  const showJobRoles = inviteRoles.length > 0 && inviteRoles[0];

  const onSelect = useCallback(
    (roleAttr, roleName, roleId) => {
      if (roleAttr.length === 0) {
        setSelectedRole({ name: roleName, id: roleId });
        setIsModalOpen(true);
      } else {
        setSelectedRolesAttr(selectedRole.id === roleId ? [] : [roleAttr]);
        setSelectedRole(
          selectedRole.id === roleId
            ? { name: '', id: '' }
            : { name: roleName, id: roleId },
        );
      }
    },
    [selectedRole],
  );

  const handleSelectRole = useCallback(
    (role: AccountRoleStatus) => () => {
      onSelect(role.attribute_ids, role?.name, role.id);
      if (!selectedRole.name.includes(role?.name)) {
        trackEvent(PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.ROLES_SELECTED);
      }
    },
    [onSelect, trackEvent],
  );

  useEffect(() => {
    const mapRoles = new Map(roles.map(role => [role?.name, role]));

    const resultInvites = inviteRoles.map(role => {
      const existingRole = mapRoles.get(role?.name);
      if (!existingRole) {
        return { ...role, status: 'custom' };
      }
      return { ...existingRole, status: 'ordered' };
    });

    const mapInviteRoles = new Map(inviteRoles.map(role => [role?.name, role]));
    const resultRoles = roles
      .filter(role => !mapInviteRoles.has(role?.name))
      .map(role => ({ ...role }));

    setMarkedRoles([...resultInvites, ...resultRoles]);
  }, [roles, inviteRoles]);

  useEffect(() => {
    if (
      inviteRoles.length === 1 &&
      inviteRoles[0] &&
      inviteRoles[0].name &&
      inviteRoles[0].id &&
      inviteRoles[0].attribute_ids
    ) {
      handleSelectRole(inviteRoles[0])();
    }
  }, [inviteRoles]);

  const handleAddRole = useCallback(() => {
    setSelectedRole(() => ({ name: '', id: '' }));
    setSelectedRolesAttr([]);
    setIsModalOpen(true);

    trackEvent(
      PURCHASE_RECOMMENDATION_ORDERING_EVENT_NAMES.NEW_ROLE_BUTTON_CLICKED,
      {
        'Location Selected': 'Order BGC Flow',
      },
    );
  }, [setSelectedRolesAttr, trackEvent]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    refetch();
  }, [refetch]);

  return (
    <>
      <StyledHeaderSection>
        <StyledQuestion>
          <h5 className='mb-0'>{t('rolesTitle')}</h5>
          <StyledSubTitle>
            {t('rolesSubTitle')}{' '}
            <M.Tooltip label={t('rolesTooltip')} align='top'>
              <M.Icon icon='InformationFilled' />
            </M.Tooltip>
          </StyledSubTitle>
        </StyledQuestion>
        <M.Button
          kind='tertiary'
          data-testid='add-new-role-button'
          onClick={handleAddRole}
        >
          <M.Icon icon='Add' /> {t('newRoleBtn')}
        </M.Button>
      </StyledHeaderSection>
      {showJobRoles && (
        <StyledScrollWrapper>
          <StyledRolesContainer>
            {markedRoles?.map(role => (
              <StyledRoleTile
                key={role.id}
                role={role.name}
                selected={selectedRole.id === role.id}
                className={role.status}
                addable={role.status === 'custom'}
                onClick={handleSelectRole(role)}
              />
            ))}
          </StyledRolesContainer>
        </StyledScrollWrapper>
      )}
      <RoleDefinitionModal
        modalOpen={isModalOpen}
        closeModal={handleCloseModal}
        setSelectedRolesAttr={setSelectedRolesAttr}
        setSelectedRole={setSelectedRole}
        role={selectedRole}
      />
    </>
  );
};

export default RecommendationsSection;
