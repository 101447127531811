import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import { namespace } from 'components/BetterOrderExperience/locales';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import { StyledScreeningContainer } from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningProductType } from '../../AddonsStep.enums';
import type { AddonsT } from '../..';
import { StyledReferenceDropdown } from './VerificationSection.styles';
import { REFERENCE_VERIFICATION_OPTIONS } from './VerificationSection.const';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: boolean;
  isIncluded: boolean;
  selectedAdditionalProperties?: AddonsT.ProfessionalReferenceVerificationProps;
  addOnPrices?: AddonsT.AddonPrice[];
  screeningSectionId: string;
  recommendedAddOnsList?: Set<string>;
  sectionName: string;
};

const ProfessionalReferenceVerification: React.FC<Props> = ({
  id,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  selectedAdditionalProperties,
  addOnPrices = [],
  screeningSectionId,
  recommendedAddOnsList,
  sectionName,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.verificationSection.professionalReferenceVerification',
  });

  const selectedQuantity = selectedAdditionalProperties?.required_quantity[1];
  const initialSelected = selectedQuantity
    ? REFERENCE_VERIFICATION_OPTIONS.filter(
        option => option.value === selectedQuantity.toString(),
      )[0]
    : REFERENCE_VERIFICATION_OPTIONS[0];

  const price = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
    undefined,
    undefined,
    selectedAdditionalProperties?.required_quantity[1] ?? '1',
  );

  const handleAddClick = useCallback(() => {
    onAddClick(
      ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
      price,
      {
        professional_reference_verification: {
          required_quantity: [true, '1'],
        },
      },
    );
  }, [onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION);
  }, [onRemoveClick]);

  const getLabel = useCallback(proRefVerification => {
    return proRefVerification.label;
  }, []);

  const handleDropdownChange = useCallback(
    ({ selectedItem }) => {
      const newPrice = getPricesByScreeningType(
        addOnPrices,
        ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
        undefined,
        undefined,
        selectedItem.value,
      );
      onAddClick(
        ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION,
        newPrice,
        {
          professional_reference_verification: {
            required_quantity: [true, selectedItem.value],
          },
        },
      );
    },
    [addOnPrices, onAddClick],
  );

  return (
    <StyledScreeningContainer>
      <ScreeningSectionItem
        id={id}
        description={t('description')}
        advantages={t('advantages', { returnObjects: true })}
        disadvantages={t('considerations', {
          returnObjects: true,
        })}
        price={
          !selectedAdditionalProperties ? (
            price
          ) : (
            <StyledReferenceDropdown
              data-testid='professional-reference-verification-dropdown'
              id='professional-reference-verification-dropdown'
              label=''
              titleText=''
              items={REFERENCE_VERIFICATION_OPTIONS}
              itemToString={getLabel}
              onChange={handleDropdownChange}
              initialSelectedItem={initialSelected}
            />
          )
        }
        screeningName={t('name')}
        recommendedAddOnsList={recommendedAddOnsList}
        screeningSectionId={screeningSectionId}
        sectionName={sectionName}
      />

      <AddButtonContainer>
        <ScreeningSectionItemButton
          id={id}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          added={isAdded}
          disabled={false}
          included={isIncluded}
        />
      </AddButtonContainer>
    </StyledScreeningContainer>
  );
};

export default ProfessionalReferenceVerification;
