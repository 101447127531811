import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { StyledFlex } from '../AddonsStep.styles';

export const DomesticScreeningsListContainer = styled(StyledFlex)`
  margin-top: 1rem;
  flex-direction: column;
`;

export const DomesticScreeningsListAccordion = styled(M.Accordion)`
  margin-top: 24px;

  .cds--accordion__item:last-child,
  li.cds--accordion__item.cds--accordion__item--active.mastodon-accordion-item {
    border-bottom: none;
    border-top: none;
  }

  .cds--accordion__content {
    padding-right: 0;
  }

  button.cds--accordion__heading:hover {
    background-color: transparent;
  }

  .cds--accordion__heading: focus {
    box-shadow: none;
  }
`;

export const DomesticScreeningsListAccordionItem = styled(M.AccordionItem)`
  .cds--accordion__item:last-child,
  li.cds--accordion__item.cds--accordion__item--active.mastodon-accordion-item {
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;

    &.highlighted {
      border: 1px solid ${colors.uiAqua400} !important;
      box-shadow: 0 0 1px 2px ${colors.uiAqua200};
    }
  }
`;

export const DomesticScreeningsListText = styled.p.attrs({
  className: 'p3',
})<{ isTitle?: boolean }>`
  color: ${({ isTitle }) => (isTitle ? colors.uiGrey900 : colors.uiGrey700)};
  font-weight: ${({ isTitle }) => (isTitle ? '700' : '400')};
`;
