import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import omit from 'lodash/omit';
import { namespace } from 'components/BetterOrderExperience/locales';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import { COMMERCIAL_MVR_ADD_ON_FLAG_KEY } from 'Flags';
import type { AddonsT } from '../..';
import { ScreeningProductType } from '../../AddonsStep.enums';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import CommercialMotorVehicle from './CommercialMotorVehicle';
import {
  StyledScreeningContainer,
  StyledFlex,
  StyledAdditionalPropContainer,
} from '../../AddonsStep.styles';

type Props = {
  id: string;
  price: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: boolean;
  isIncluded: boolean;
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
  recommendedAddOnsList?: Set<string>;
  sectionName?: string;
  screeningSectionId: string;
};
const MotorVehicleReport = ({
  id,
  price,
  onAddClick,
  onRemoveClick,
  selectedAdditionalProperties,
  setSelectedAdditionalProperties,
  isAdded,
  isIncluded,
  recommendedAddOnsList,
  sectionName,
  screeningSectionId,
}: Props) => {
  const [commercialChecked, setCommercialChecked] = useState(false);
  const { t } = useTranslation(namespace, { keyPrefix: 'addOns.mvrSection' });
  const commercialMvrAddonKey = useFlag(COMMERCIAL_MVR_ADD_ON_FLAG_KEY);
  const commercialMvrEnabled = commercialMvrAddonKey?.variantKey === 'enabled';

  const showCommercialAddon = commercialMvrEnabled && !isIncluded;

  const handleAddClick = useCallback(() => {
    onAddClick(ScreeningProductType.MOTOR_VEHICLE_REPORT, price);
  }, [onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.MOTOR_VEHICLE_REPORT);
    setCommercialChecked(false);
  }, [onRemoveClick]);

  const handleCommercialMVRChange = useCallback(
    e => {
      const { checked } = e.target;
      const mvrType = checked ? 'commercial' : '';
      const additionalProperties = {
        motor_vehicle_report: {
          mvr_type: [checked, mvrType] as [boolean, string],
          perform_cdlis_after_current_dl: checked,
          perform_additional_license_after_cdlis: false,
        },
      };

      setCommercialChecked(checked);
      if (checked) {
        onAddClick(
          ScreeningProductType.MOTOR_VEHICLE_REPORT,
          price,
          additionalProperties,
        );
      } else {
        const removedAdditionalProperties = omit(
          selectedAdditionalProperties,
          ScreeningProductType.MOTOR_VEHICLE_REPORT,
        );
        setSelectedAdditionalProperties(removedAdditionalProperties);
      }
    },
    [
      onAddClick,
      price,
      selectedAdditionalProperties,
      setSelectedAdditionalProperties,
    ],
  );

  return (
    <StyledScreeningContainer flexDirection='column'>
      <StyledFlex>
        <ScreeningSectionItem
          id={id}
          description={t('screenings.mvr.description')}
          advantages={t('screenings.mvr.advantages', { returnObjects: true })}
          disadvantages={t('screenings.mvr.disadvantages', {
            returnObjects: true,
          })}
          price={price}
          screeningName={t('screenings.mvr.name')}
          recommendedAddOnsList={recommendedAddOnsList}
          sectionName={sectionName}
          screeningSectionId={screeningSectionId}
        />
        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            added={isAdded}
            included={isIncluded}
          />
        </AddButtonContainer>
      </StyledFlex>
      {showCommercialAddon && (
        <StyledAdditionalPropContainer>
          <CommercialMotorVehicle
            onChange={handleCommercialMVRChange}
            checked={commercialChecked}
          />
        </StyledAdditionalPropContainer>
      )}
    </StyledScreeningContainer>
  );
};

export default MotorVehicleReport;
