import React from 'react';
import isEqual from 'lodash/isEqual';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { usePreference } from 'api/dashboardPreferences';

import { View } from '../../state/types';
// import ColumnsButton from './columns-button';
import ExportButton from './export-button';
import FiltersButton from './filters-button';
import ResetButton from './reset-button';
import SaveButton from './save-button';
import SearchBar from './search-bar';
import './styles.scss';

type Props = {
  isLoading: boolean;
  view: View;
  reports?: GenericObject;
};

const ActionsBar: React.FC<Props> = ({ isLoading, view, reports }) => {
  // TODO: get savedView from the backend
  const [savedView] = usePreference('searchViews');

  // Filters must match excluding page, Columns much match exactly
  const canSave =
    !savedView ||
    !isEqual({ ...savedView.filters, page: 1 }, { ...view.filters, page: 1 });

  return (
    <>
      {view && (
        <>
          <div className='reports-list-actions-search-bar'>
            <SearchBar />
          </div>

          <div className='reports-list-actions-flex-container'>
            <div className='reports-list-actions-left'>
              <div className='reports-list-actions-filters-dropdown'>
                <FiltersButton isLoading={isLoading} />
                {canSave && <div className='reports-list-actions-divider' />}
              </div>

              <div className='reports-list-actions-filters'>
                <div className='reports-list-placeholder-button'>Filter</div>
                <div className='reports-list-placeholder-button'>Filter</div>
                <div className='reports-list-placeholder-button'>Filter</div>
                <div className='reports-list-placeholder-button'>Filter</div>
                <div className='reports-list-placeholder-button'>Filter</div>
                {canSave && (
                  <div>
                    <SaveButton view={view} isLoading={isLoading} />
                    <ResetButton savedView={savedView} />
                  </div>
                )}
              </div>
            </div>

            <div className='reports-list-actions-right'>
              {isLoading && <M.LoadingInline description='Searching...' />}
              <ExportButton isLoading={isLoading} reports={reports} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActionsBar;
