import { FormFieldsPropsType } from './PaymentProfileForm';

const BaseFormProps = {
  email: {
    label: 'payment_profile.form.email.label',
    placeholder: 'payment_profile.form.email.placeholder',
    disabled: false,
  },
  address_line_1: {
    label: 'billing_address.add_address_form.address_1.label',
    placeholder: 'billing_address.add_address_form.address_1.placeholder',
    disabled: false,
  },
  address_line_2: {
    label: 'billing_address.add_address_form.address_2.label',
    placeholder: 'billing_address.add_address_form.address_2.placeholder',
    disabled: false,
  },
  city: {
    label: 'billing_address.add_address_form.city.label',
    placeholder: 'billing_address.add_address_form.city.placeholder',
    disabled: false,
  },
  region: {
    label: 'billing_address.add_address_form.state.label',
    placeholder: 'billing_address.add_address_form.state.placeholder',
    disabled: false,
  },
  postal_code: {
    label: 'billing_address.add_address_form.zip.label',
    placeholder: 'billing_address.add_address_form.zip.placeholder',
    disabled: false,
  },
  country: {
    label: 'billing_address.add_address_form.country.label',
    placeholder: 'billing_address.add_address_form.country.placeholder',
    disabled: false,
  },
};

export const PaymentProfileFormProps: FormFieldsPropsType = {
  form_label: 'Payment Profile Form',
  form_fields: {
    id: {
      label: 'payment_profile.form.id.profile_label',
      placeholder: 'payment_profile.form.id.profile_placeholder',
      disabled: false,
    },
    name: {
      label: 'payment_profile.form.name.profile_label',
      placeholder: 'payment_profile.form.name.profile_placeholder',
      disabled: false,
    },
    ...BaseFormProps,
  },
};

export const NodeBillingFormProps: FormFieldsPropsType = {
  form_label: 'Add billable node',
  form_fields: {
    id: {
      label: 'payment_profile.form.id.node_label',
      placeholder: 'payment_profile.form.id.node_placeholder',
      disabled: true,
    },
    name: {
      label: 'payment_profile.form.name.node_label',
      placeholder: 'payment_profile.form.name.node_placeholder',
      disabled: true,
    },
    ...BaseFormProps,
  },
};
