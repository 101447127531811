import React from 'react';
import { CategoryTitle } from 'components/AddScreenings/shared/ListItemElements';
import {
  Icon,
  Accordion,
  AccordionItem,
  AccordionContent,
} from './ScreeningSection.styles';
import { ScreeningItemContainer } from '../ScreeningSectionItem/ScreeningSectionItem.styles';

type Props = {
  id: string;
  name: string;
  icon: string;
  screeningSectionId?: string;
  recommendedAddOnsList?: Set<string>;
};

const ScreeningSection: React.FC<Props> = ({
  id,
  name,
  icon,
  children,
  screeningSectionId,
  recommendedAddOnsList,
}) => {
  return (
    <>
      {!recommendedAddOnsList ? (
        <Accordion className={screeningSectionId}>
          <AccordionItem
            title={
              <CategoryTitle data-testid={`${id}-section-title`}>
                <Icon src={icon} data-testid={`${id}-section-icon`} />
                {name}
              </CategoryTitle>
            }
          >
            <AccordionContent data-testid={`${id}-section-content`}>
              <ScreeningItemContainer>{children}</ScreeningItemContainer>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ScreeningSection;
