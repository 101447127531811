import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Modal = styled(M.ComposedModal)`
  z-index: 999999;
  .cds--modal-container {
    width: 100%;
    max-width: 40rem;
    min-height: 20rem;
    max-height: 90vh;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 90vw;
      min-height: 50vh;
    }

    @media (max-width: 424px) {
      max-width: 95vw;
      min-height: 60vh;
    }
  }

  .cds--modal-scroll-content {
    overflow: auto;
  }

  &:focus {
    outline: none;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  > svg {
    fill: #0a57a4;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  align-items: center;
`;

export const BackButtonWrapper = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

export const StyledModalFooter = styled(M.ModalFooter)`
  display: flex;
  padding: 16px 24px !important;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  border-top: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  background: var(--ui-bg-primary-grey-0, #fff);
  box-shadow: 0px 0px 20px rgba(22, 22, 29, 0.12);

  @media (max-width: 768px) {
    padding: 12px 16px !important;
  }
`;

export const StyledTitleWrap = styled.div`
  margin-bottom: 32px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const StyledSubTitle = styled.p.attrs({
  className: 'p3',
})`
  color: ${colors.uiTextTertiaryLight} !important;
`;

export const ProgressBarContainer = styled.div`
  height: 5px;
  background: var(--ui-border-primary-grey-200, #e1e6eb);
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const ModalContentContainer = styled.div`
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
`;

export const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  width: ${({ progress }) => `${progress * 100}%`};
  background: var(--aqua-500, #009cb8);
  transition: width 0.3s ease;
`;

export const StyledHeaderSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledQuestion = styled.div`
  margin-bottom: 0;
`;

export const StyledTooltip = styled(M.Tooltip)`
  #mastodon & {
    &.cds--popover-container {
      background-color: ${colors.uiGrey100};
      border-radius: 4px;
      color: ${colors.uiTextSecondaryLight};
      padding: 6px;
      display: flex;
      align-items: flex-start;
      gap: 7px;

      @media (max-width: 768px) {
        padding: 6px 10px 6px 6px;
        max-width: 145px;
      }

      svg {
        color: ${colors.uiGrey0};
        fill: ${colors.uiGrey500};
      }
    }
    .button-reset {
      color: ${colors.uiTextSecondaryLight};
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const TooltipContent = styled.span`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(M.Button)`
  @media (max-width: 768px) {
    display: flex;
    padding: 12px 14px;
    justify-content: flex-end;
    gap: 8px;
  }
`;

export const SubmitButton = styled(M.Button)`
  position: relative;
  min-width: 120px;
`;

export const LoadingState = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const SubmitButtonText = styled.span<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;
