import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import {
  StyledQuestion,
  StyledHeaderSection,
  StyledSubTitle,
  StyledTooltip,
  TooltipContent,
} from './PurchaseRecommendationModal.styles';
import { namespace } from '../../locales';

interface Props {
  tKey: string;
  searchParam: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterSection: React.FC<Props> = ({
  tKey,
  searchParam,
  handleSearchChange,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });

  const currentUser = useUser();
  const [tooltipAlign, setTooltipAlign] = useState<'bottom-right' | 'right'>(
    'bottom-right',
  );

  useEffect(() => {
    const updateTooltipAlign = () => {
      if (window.innerWidth < 769) {
        setTooltipAlign('right');
      } else {
        setTooltipAlign('bottom-right');
      }
    };

    updateTooltipAlign();
    window.addEventListener('resize', updateTooltipAlign);
    return () => window.removeEventListener('resize', updateTooltipAlign);
  }, []);

  return (
    <>
      <StyledHeaderSection>
        <StyledQuestion>
          <h5 className='mb-0'>
            {t(`${tKey}.question`, { accountName: currentUser?.account?.name })}
          </h5>
          <StyledSubTitle>{t(`${tKey}.comment`)}</StyledSubTitle>
        </StyledQuestion>
        <StyledTooltip
          align={tooltipAlign}
          label={t('tooltipLabel')}
          highlighted={false}
        >
          <M.Icon icon='InformationFilled' />
          <TooltipContent>{t('tooltip')}</TooltipContent>
        </StyledTooltip>
      </StyledHeaderSection>
      <M.Search
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
        placeholder={t(`${tKey}.searchLabel`)}
      />
    </>
  );
};

export default FilterSection;
