export enum ScreeningProductType {
  FEDERAL_CRIMINAL_SEARCH = 'federal_criminal_search',
  COUNTY_CIVIL_SEARCH = 'county_civil_search',
  PROFESSIONAL_REFERENCE_VERIFICATION = 'professional_reference_verification',
  CREDIT_REPORT = 'credit_report',
  FEDERAL_CIVIL_SEARCH = 'federal_civil_search',
  EMPLOYMENT_VERIFICATION = 'employment_verification',
  COUNTY_CRIMINAL_SEARCH = 'county_criminal_search',
  EDUCATION_VERIFICATION = 'education_verification',
  STATE_CRIMINAL_SEARCH = 'state_criminal_search',
  GLOBAL_WATCHLIST_SEARCH = 'global_watchlist_search',
  FACIS_SEARCH = 'facis_search',
  MOTOR_VEHICLE_REPORT = 'motor_vehicle_report',
  PROFESSIONAL_LICENSE_VERIFICATION = 'pro_license_verification',
  PERSONAL_REFERENCE_VERIFICATION = 'personal_reference_verification',
  DRUG_SCREENING = 'drug_screening',
  OCCUPATIONAL_HEALTH_SCREENING = 'occupational_health_screening',
  SSN_TRACE = 'ssn_trace',
  SEX_OFFENDER_SEARCH = 'sex_offender_search',
  NATIONAL_CRIMINAL_SEARCH = 'national_criminal_search',
  INTERNATIONAL_ADVERSE_MEDIA_SEARCH = 'international_adverse_media_search',
  INTERNATIONAL_CRIMINAL_SEARCH = 'international_criminal_search_v2',
  INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH = 'international_global_watchlist_search',
  INTERNATIONAL_EDUCATION_VERIFICATION = 'international_education_verification',
  INTERNATIONAL_EMPLOYMENT_VERIFICATION = 'international_employment_verification',
  INTERNATIONAL_IDENTITY_DOCUMENT_VALIDATION = 'international_identity_document_validation',
  INTERNATIONAL_RIGHT_TO_WORK = 'international_right_to_work',
}

export enum CreditReportStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum OccHealthStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum DrugTestStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export const ScreeningClassMap: Record<string, string> = {
  federal_criminal: 'federal_criminal_search',
  county_civil: 'county_civil_search',
  professional_reference: 'professional_reference_verification',
  credit_report: 'credit_report',
  federal_civil: 'federal_civil_search',
  employment_verification: 'employment',
  dot_employment: 'employment',
  state_civil: 'county_criminal_search',
  education: 'education_verification',
  state_criminal: 'state_criminal_search',
  global_watchlist: 'global_watchlist_search',
  facis: 'facis_search',
  mvr: 'motor_vehicle_report',
  mvr_with_cdlis: 'motor_vehicle_report',
  professional_license: 'pro_license_verification', //
  plv: 'personal_reference_verification', //
  drug_alcohol: 'drug_screening',
  dot_drug_alcohol: 'drug_screening',
  occupational_health: 'occupational_health_screening',
  dot_physical: 'occupational_health_screening',
  healthcare_sanctions: 'occupational_health_screening',
  ssn_trace: 'ssn_trace',
  sex_offender: 'sex_offender_search',
  national_criminal: 'national_criminal_search',
};
