import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import JobRoleAttributeTile from './JobRoleAttributeTile/JobRoleAttributeTile';
import useGetJobRoleAttributes from '../../hooks/useGetJobRoleAttributes';

import {
  Container,
  ResponsibilitiesTitle,
  CheckboxContainer,
  RoleNameText,
  ResponsibilitiesText,
  SelectAllText,
  TellUsMoreText,
  WhatShouldWeCallThisRoleText,
  CardsContainer,
  Divider,
} from './RoleDefinitionSectionStyles';
import { namespace } from '../../locales';

interface RoleDefinitionSectionProps {
  roleName: string;
  roleNumber: number;
  onValidityUpdate?: (isValid: boolean) => void;
  onAttributesUpdate: (roleName: string, attributes: number[]) => void;
  orderFlow?: boolean;
  customRoleName?: string;
  onCustomRoleNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RoleDefinitionSection: React.FC<RoleDefinitionSectionProps> = ({
  roleName,
  roleNumber,
  onValidityUpdate,
  onAttributesUpdate,
  orderFlow,
  customRoleName,
  onCustomRoleNameChange,
}) => {
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);

  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });

  const { data } = useGetJobRoleAttributes();

  const [isRoleDefinedLater, setIsRoleDefinedLater] = useState<boolean>(false);

  useEffect(() => {
    onAttributesUpdate(roleName, isRoleDefinedLater ? [] : selectedAttributes);
    if (onValidityUpdate) {
      onValidityUpdate(!isRoleDefinedLater && selectedAttributes.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidityAndUpdate = useCallback(
    (isDefinedLater: boolean, attributes: number[]) => {
      if (onValidityUpdate) {
        onValidityUpdate(isDefinedLater || attributes.length > 0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleTileClick = useCallback(
    (id: number) => {
      const updatedSelection = selectedAttributes.includes(id)
        ? selectedAttributes.filter(attrId => attrId !== id)
        : [...selectedAttributes, id];

      setSelectedAttributes(updatedSelection);
      onAttributesUpdate(roleName, updatedSelection);
      checkValidityAndUpdate(isRoleDefinedLater, updatedSelection);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRoleDefinedLater, selectedAttributes],
  );

  const handleCheckboxChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const isChecked = event.target.checked;
      setIsRoleDefinedLater(isChecked);
      onAttributesUpdate(roleName, isChecked ? [] : selectedAttributes);
      checkValidityAndUpdate(isChecked, selectedAttributes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAttributes],
  );

  const createHandleTileClick = (id: number) => () => handleTileClick(id);

  const handleCustomRoleNameKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        (e.target as HTMLInputElement).blur();
      }
    },
    [],
  );

  const handleCustomRoleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onCustomRoleNameChange) {
        onCustomRoleNameChange(e);
      }
    },
    [onCustomRoleNameChange],
  );

  let displayedAttributes = [];
  let otherAttributes = [];

  if (data?.job_role_attributes) {
    const jobRoleAttributes = data.job_role_attributes;
    displayedAttributes = jobRoleAttributes.slice(0, 13);
    otherAttributes = jobRoleAttributes.slice(-5);
  }

  return (
    <Container data-testid='container-role-definition-section'>
      {!orderFlow && <Divider />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {orderFlow ? (
          <>
            {roleName === '' ? (
              <WhatShouldWeCallThisRoleText>
                {t('roleDefinitionSection.createNewRoleText')}
              </WhatShouldWeCallThisRoleText>
            ) : (
              <TellUsMoreText>
                {t('roleDefinitionSection.tellUsMoreText')}
              </TellUsMoreText>
            )}
          </>
        ) : (
          <>
            <ResponsibilitiesTitle>
              {t('roleDefinitionSection.role', { roleNumber })}
            </ResponsibilitiesTitle>
            <CheckboxContainer>
              <label htmlFor={`role-checkbox-${roleNumber}`}>
                {t('roleDefinitionSection.defineThisRoleLater')}
              </label>
              <M.Checkbox
                type='checkbox'
                id={`role-checkbox-${roleNumber}`}
                checked={isRoleDefinedLater}
                onChange={handleCheckboxChange}
              />
            </CheckboxContainer>
          </>
        )}
      </div>
      {roleName === '' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '8px',
            position: 'relative',
            width: '100%',
          }}
        >
          {/* placeholder styling - remove after design is updated */}
          <M.TextInput
            data-testid='custom-role-name-input'
            placeholder='Custom role'
            value={customRoleName}
            onChange={handleCustomRoleNameChange}
            onKeyDown={handleCustomRoleNameKeyDown}
            style={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '700',
              borderBottom: 'none',
              paddingLeft: 0,
              paddingRight: '44px',
              color: '#1A2026',
              fontFamily: 'National 2',
              '&:focus': {
                borderBottom: 'none',
                borderBottomWidth: 0,
                borderBottomStyle: 'none',
                borderBottomColor: 'transparent',
              },
            }}
          />
          <svg
            width='36'
            height='36'
            viewBox='0 0 36 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.25 24.25H26.75V25.5H9.25V24.25ZM23.875 13.625C24.375 13.125 24.375 12.375 23.875 11.875L21.625 9.625C21.125 9.125 20.375 9.125 19.875 9.625L10.5 19V23H14.5L23.875 13.625ZM20.75 10.5L23 12.75L21.125 14.625L18.875 12.375L20.75 10.5ZM11.75 21.75V19.5L18 13.25L20.25 15.5L14 21.75H11.75Z'
              fill='#1A2026'
              fillOpacity='0.65'
            />
          </svg>
        </div>
      ) : (
        <RoleNameText>{roleName}</RoleNameText>
      )}
      {!isRoleDefinedLater && (
        <>
          <ResponsibilitiesText>
            {t('roleDefinitionSection.responsibilitiesText', { roleName })}
          </ResponsibilitiesText>
          <SelectAllText>
            {t('roleDefinitionSection.selectAllText')}
          </SelectAllText>
          <CardsContainer>
            {displayedAttributes?.map(
              (attr: { id: number; name: string; svg_s3_url: string }) => (
                <JobRoleAttributeTile
                  key={attr.id}
                  id={attr.id}
                  name={attr.name}
                  icon={attr.svg_s3_url}
                  selected={selectedAttributes.includes(attr.id)}
                  onClick={createHandleTileClick(attr.id)}
                />
              ),
            )}
          </CardsContainer>
          <ResponsibilitiesText>
            {t('roleDefinitionSection.otherRequirementsText', { roleName })}
          </ResponsibilitiesText>
          <SelectAllText>
            {t('roleDefinitionSection.selectAllText')}
          </SelectAllText>
          <CardsContainer>
            {otherAttributes?.map(
              (attr: { id: number; name: string; svg_s3_url: string }) => (
                <JobRoleAttributeTile
                  key={attr.id}
                  id={attr.id}
                  name={attr.name}
                  icon={attr.svg_s3_url}
                  selected={selectedAttributes.includes(attr.id)}
                  onClick={createHandleTileClick(attr.id)}
                />
              ),
            )}
          </CardsContainer>
        </>
      )}
    </Container>
  );
};

export default RoleDefinitionSection;
