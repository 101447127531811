import React, { useState, useCallback, useEffect, useRef } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  useTrackEvent,
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
} from 'utils/analytics';
import IndustrySelection from './IndustrySelection';
import RoleSelection from './RoleSelection';
import RoleDefinition from './RoleDefinition';
import useUpdateAccountIndustry from '../../hooks/usePutAccountIndustry';
import usePostJobRoles from '../../hooks/usePostJobRoles';
import {
  Modal,
  CloseButton,
  ButtonGroup,
  BackButtonWrapper,
  StyledTitleWrap,
  StyledSubTitle,
  ProgressBarContainer,
  ProgressBar,
  StyledModalFooter,
  ModalContentContainer,
} from './PurchaseRecommendationModal.styles';
import { namespace } from '../../locales';

type ModalProps = {
  closeModal: (step?: string) => void;
  modalOpen: boolean;
  hasOrdered: boolean;
};

const PurchaseRecommendationModal: React.FC<ModalProps> = ({
  closeModal,
  modalOpen,
  hasOrdered,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });

  const { mutate: updateIndustry } = useUpdateAccountIndustry();
  const { mutate: createJobRoles } = usePostJobRoles();

  const [currentStep, setCurrentStep] = useState(1);
  const [industrySelection, setIndustrySelection] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [roleSelection, setRoleSelection] = useState<string[]>([]);
  const [definitionSelection, setDefinitionSelection] = useState<number | null>(
    null,
  );
  const [customIndustry, setCustomIndustry] = useState<string>('');
  const [areRoleDefinitionsValid, setAreRoleDefinitionsValid] = useState(false);

  const [roleAttributes, setRoleAttributes] = useState<
    Record<string, number[]>
  >({});

  // Add state to track the previous industry selections
  const [prevIndustrySelection, setPrevIndustrySelection] =
    useState(industrySelection);
  const [prevCustomIndustry, setPrevCustomIndustry] = useState(customIndustry);

  const trackEvent = useTrackEvent();
  const hasTrackedIndustryView = useRef(false);
  const hasTrackedIndustrySearch = useRef(false);
  const hasTrackedRoleSearch = useRef(false);
  const hasTrackedRoleView = useRef(false);

  const handleSubmitRolesWithoutAttributes = useCallback(() => {
    const jobRolesData = roleSelection.map(roleName => ({
      name: roleName,
      attribute_ids: [],
    }));

    createJobRoles(jobRolesData);

    trackEvent(
      PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLE_DEFINITION_SKIPPED,
    );
  }, [roleSelection, createJobRoles, trackEvent]);

  const handleSubmitRoles = useCallback(() => {
    const jobRolesData = Object.keys(roleAttributes).map(roleName => ({
      name: roleName,
      attribute_ids: roleAttributes[roleName] || [],
    }));

    const areAllAttributesEmpty = jobRolesData.every(
      role => role.attribute_ids.length === 0,
    );

    if (areAllAttributesEmpty) {
      trackEvent(
        PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLE_DEFINITION_SKIPPED,
      );
    } else {
      const nonEmptyRolesCount = jobRolesData.filter(
        role => role.attribute_ids.length > 0,
      ).length;
      trackEvent(
        PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLE_DEFINITION_SKIPPED,
        {
          'Number of Roles Completed': nonEmptyRolesCount,
        },
      );
    }

    createJobRoles(jobRolesData);
  }, [roleAttributes, createJobRoles, trackEvent]);

  const stepName = useCallback((step: number): string | undefined => {
    switch (step) {
      case 2:
        return 'Roles Page';
      case 3:
        return 'Define Roles Page';
      default:
        return undefined;
    }
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        if (modalOpen && !hasTrackedIndustryView.current) {
          trackEvent(
            PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.INDUSTRY_SELECTION_VIEWED,
          );
          hasTrackedIndustryView.current = true;
        }
        break;
      case 2:
        if (modalOpen && !hasTrackedRoleView.current) {
          trackEvent(
            PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_VIEWED,
          );
          hasTrackedRoleView.current = true;
        }
        break;
      default:
        break;
    }
  }, [modalOpen, currentStep, trackEvent]);

  const handleContinue = useCallback(() => {
    setCurrentStep(prev => {
      if (prev === 1 && industrySelection?.id != null) {
        if (industrySelection?.id !== prevIndustrySelection?.id) {
          updateIndustry(industrySelection.id);
          setPrevIndustrySelection(industrySelection);
          trackEvent(
            PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.INDUSTRY_SELECTION_COMPLETED,
            {
              'Industry Selected': industrySelection.name,
            },
          );
        }

        // if User has selected a Custom Industry
        if (
          industrySelection?.id === 22 &&
          customIndustry !== prevCustomIndustry
        ) {
          setPrevCustomIndustry(customIndustry);
          trackEvent(
            PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.INDUSTRY_SELECTION_CUSTOM_USED,
            {
              'Industry Inputted': customIndustry,
            },
          );
        }
      }
      if (prev === 2) {
        trackEvent(
          PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_SELECTED,
          {
            'Roles Selected': roleSelection.length,
            roleSelection,
          },
        );
        trackEvent(
          PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_COMPLETED,
        );
      }
      return prev + 1;
    });
  }, [
    industrySelection,
    prevIndustrySelection?.id,
    customIndustry,
    prevCustomIndustry,
    roleSelection,
    updateIndustry,
    trackEvent,
  ]);

  const handleBack = useCallback(() => {
    setCurrentStep(prev => prev - 1);
    setDefinitionSelection(0);
    setAreRoleDefinitionsValid(false);
  }, []);

  const handleConfirmClick = useCallback(() => {
    if (!industrySelection) return;

    updateIndustry(industrySelection.id);
    trackEvent(
      PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.INDUSTRY_SELECTION_COMPLETED,
      {
        'Industry Selected': industrySelection.name,
      },
    );

    closeModal();
  }, [industrySelection, closeModal, updateIndustry, trackEvent]);

  const handleSkip = useCallback(() => {
    if (currentStep === 3) {
      handleSubmitRolesWithoutAttributes();
      trackEvent(
        PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLE_DEFINITION_SKIPPED,
      );
      closeModal(stepName(currentStep));
    } else if (currentStep === 2) {
      trackEvent(
        PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_SKIPPED,
      );
      closeModal(stepName(currentStep));
    } else {
      setCurrentStep(prev => prev + 1);
    }
  }, [
    currentStep,
    handleSubmitRolesWithoutAttributes,
    closeModal,
    stepName,
    trackEvent,
  ]);

  const handleSubmitClick = useCallback(() => {
    if (definitionSelection === 1) {
      handleSubmitRoles();
    } else {
      handleSubmitRolesWithoutAttributes();
    }
    trackEvent(
      PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ONBOARDING_COMPLETED,
    );
    closeModal();
  }, [
    closeModal,
    definitionSelection,
    handleSubmitRoles,
    handleSubmitRolesWithoutAttributes,
    trackEvent,
  ]);

  const handleCloseButtonClick = useCallback(() => {
    if (currentStep === 3) {
      handleSubmitRolesWithoutAttributes();
    }
    closeModal(stepName(currentStep));
  }, [currentStep, closeModal, stepName, handleSubmitRolesWithoutAttributes]);

  const handleOptionChange = useCallback(
    (option: number | { id: number; name: string }) => {
      switch (currentStep) {
        case 1:
          if (typeof option === 'object') {
            setIndustrySelection(option);
          }
          break;
        case 3:
          if (typeof option === 'number') {
            setDefinitionSelection(option);
          }
          break;
        default:
          break;
      }
    },
    [currentStep],
  );

  const handleRoleChange = useCallback((roles: string[]) => {
    setRoleSelection(roles);
  }, []);

  const handleAttributeUpdate = useCallback(
    (roleName: string, attributes: number[]) => {
      setRoleAttributes(prev => ({
        ...prev,
        [roleName]: attributes,
      }));
    },
    [],
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <IndustrySelection
            selectedOption={industrySelection?.id ?? null}
            onOptionChange={handleOptionChange}
            customIndustry={customIndustry}
            setCustomIndustry={setCustomIndustry}
            hasTrackedIndustrySearch={hasTrackedIndustrySearch}
          />
        );
      case 2:
        return (
          <RoleSelection
            selectedRoles={roleSelection}
            onRolesChange={handleRoleChange}
            selectedIndustryId={industrySelection?.id as number}
            hasTrackedRoleSearch={hasTrackedRoleSearch}
          />
        );
      case 3:
        return (
          <RoleDefinition
            selectedRoles={roleSelection}
            onDefineLaterChange={setDefinitionSelection}
            onValidityChange={setAreRoleDefinitionsValid}
            handleAttributeUpdate={handleAttributeUpdate}
          />
        );
      default:
        return null;
    }
  };

  const isIndustrySelected = () => {
    const isBasicIndustrySelected =
      industrySelection?.id !== 22 && industrySelection !== null;
    const isCustomIndustry =
      industrySelection?.id === 22 && customIndustry.trim() !== '';

    return isBasicIndustrySelected || isCustomIndustry;
  };

  const isContinueEnabled = (() => {
    switch (currentStep) {
      case 1:
        return isIndustrySelected();
      case 2:
        return roleSelection.length > 0;
      case 3:
        return definitionSelection === 2 || areRoleDefinitionsValid;
      default:
        return false;
    }
  })();

  const renderFooter = () => {
    const renderBackButton = currentStep > 1 && (
      <BackButtonWrapper>
        <M.Button kind='secondary' onClick={handleBack}>
          Back
        </M.Button>
      </BackButtonWrapper>
    );

    const renderSkipButton = currentStep > 1 && (
      <M.Button kind='tertiary' onClick={handleSkip}>
        Skip
      </M.Button>
    );

    let actionButton;
    if (hasOrdered) {
      actionButton = (
        <M.Button
          kind='primary'
          onClick={handleConfirmClick}
          disabled={!isIndustrySelected()}
        >
          Confirm
        </M.Button>
      );
    } else if (currentStep < 3) {
      actionButton = (
        <M.Button
          kind='primary'
          onClick={handleContinue}
          disabled={!isContinueEnabled}
        >
          Continue
        </M.Button>
      );
    } else {
      actionButton = (
        <M.Button
          kind='primary'
          onClick={handleSubmitClick}
          disabled={!isContinueEnabled}
        >
          Submit
        </M.Button>
      );
    }

    return (
      <StyledModalFooter>
        <ButtonGroup>
          {renderBackButton}
          {renderSkipButton}
          {actionButton}
        </ButtonGroup>
      </StyledModalFooter>
    );
  };

  return (
    <Modal
      onClose={closeModal}
      open={modalOpen}
      data-testid='purchase-recommendation-modal'
      type='large'
      preventCloseOnClickOutside
    >
      <M.ModalBody>
        <ModalContentContainer>
          <StyledTitleWrap>
            <h3>{t('header.onboardingTitle')}</h3>
            <StyledSubTitle>{t('header.onboardingSubtitle')}</StyledSubTitle>
          </StyledTitleWrap>
          <ProgressBarContainer>
            <ProgressBar
              progress={currentStep / 3}
              data-testid='progress-bar'
            />
          </ProgressBarContainer>
          {renderStepContent()}

          {currentStep > 1 && (
            <CloseButton
              onClick={handleCloseButtonClick}
              data-testid='close-modal'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
              >
                <path d='M15 5.875L14.125 5L10 9.125L5.875 5L5 5.875L9.125 10L5 14.125L5.875 15L10 10.875L14.125 15L15 14.125L10.875 10L15 5.875Z' />
              </svg>
            </CloseButton>
          )}
        </ModalContentContainer>
      </M.ModalBody>
      {renderFooter()}
    </Modal>
  );
};

export default PurchaseRecommendationModal;
