import { useMutation } from 'react-query';
import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import useGetUser from './useGetUser';

const batchCreateJobRoles = async (
  accountId: string,
  jobRoles: { name: string; attribute_ids: number[] }[],
) => {
  const token = localStorage.getItem('accessToken');

  const response = await axios.post(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/job_roles/batch`,
    { job_roles: jobRoles },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

interface JobRoleResponse {
  job_roles: Array<{
    id: string;
    name: string;
  }>;
}

interface BatchCreateJobRolesOptions {
  onSuccess?: (response: JobRoleResponse) => void;
}

function useBatchCreateJobRoles(options?: BatchCreateJobRolesOptions) {
  const { data: user } = useGetUser();

  const [mutate, { data, isLoading, error }] = useMutation(
    async (jobRoles: { name: string; attribute_ids: number[] }[]) => {
      if (!user) {
        throw new Error('User information is not available');
      }

      return batchCreateJobRoles(user.account.id, jobRoles);
    },
    {
      onSuccess: options?.onSuccess,
      onError: error => {},
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default useBatchCreateJobRoles;

export interface JobRole {
  name: string;
  attribute_ids: number[];
}
