import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import DomesticScreeningsList from './DomesticScreeningsList';
import type { AddonsT } from '../..';
import {
  DomesticScreeningsListAccordion,
  DomesticScreeningsListAccordionItem,
  DomesticScreeningsListText,
} from './DomesticScreeningsList.styles';

type Props = {
  showRecommendations: boolean;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addedScreeningTypes: AddonsT.ScreeningType[];
  addOnPrices: AddonsT.AddonPrice[];
  includedScreenings: AddonsT.ScreeningType[];
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
  recommendedAddOnsList?: Set<string>;
  recommendationsSection?: boolean;
};

const DomesticScreeningsListWrapper: React.FC<Props> = props => {
  const [expand, setExpand] = useState(false);

  const { showRecommendations } = props;
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.recommendationsSection',
  });

  useEffect(() => {
    setExpand(true);
  }, []);

  return showRecommendations ? (
    <DomesticScreeningsListAccordion>
      <DomesticScreeningsListAccordionItem
        title={
          <>
            <DomesticScreeningsListText isTitle>
              {t('allChecks.title')}
            </DomesticScreeningsListText>
            <DomesticScreeningsListText>
              {t('allChecks.description')}
            </DomesticScreeningsListText>
          </>
        }
        open={expand}
      >
        <DomesticScreeningsList {...props} />
      </DomesticScreeningsListAccordionItem>
    </DomesticScreeningsListAccordion>
  ) : (
    <DomesticScreeningsList {...props} />
  );
};

export default DomesticScreeningsListWrapper;
