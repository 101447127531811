import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import stethoscope from 'components/AddScreenings/shared/svg/stethoscope.svg';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import FACISLevel1 from './FACISLevel1';
import FACISLevel3 from './FACISLevel3';
import FACISSelect from './FACISSelect';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  recommendedAddOnsList?: Set<string>;
  recommendationsSection?: boolean;
};

const FACISSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
  selectedAdditionalProperties,
  recommendedAddOnsList,
  recommendationsSection,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.facis',
  });
  const screeningSectionId = 'facis';

  const facisLevel1Price = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.FACIS_SEARCH,
    '1',
  );

  const facisLevel3Price = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.FACIS_SEARCH,
    '3',
  );

  const facisLevel1Disabled =
    selectedAdditionalProperties?.facis_search?.subtype === 3;

  const facisLevel3Disabled =
    selectedAdditionalProperties?.facis_search?.subtype === 1;

  const shouldRenderRecommendationsSection = () => {
    return (
      recommendationsSection &&
      recommendedAddOnsList?.has(ScreeningProductType.FACIS_SEARCH)
    );
  };

  return (
    <ScreeningSection
      id={screeningSectionId}
      name={t('sectionName')}
      icon={stethoscope}
      recommendedAddOnsList={recommendedAddOnsList}
      screeningSectionId={screeningSectionId}
    >
      <StyledFlex flexDirection='column'>
        {shouldRenderRecommendationsSection() && (
          <FACISSelect
            id={`${ScreeningProductType.FACIS_SEARCH}-level-select`}
            facisLevel1Price={facisLevel1Price}
            facisLevel3Price={facisLevel3Price}
            isAdded={isAdded(ScreeningProductType.FACIS_SEARCH)}
            isIncluded={isIncluded(ScreeningProductType.FACIS_SEARCH)}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {!recommendationsSection && (
          <FACISLevel1
            id={`${ScreeningProductType.FACIS_SEARCH}-level-1`}
            price={facisLevel1Price}
            isAdded={
              isAdded(ScreeningProductType.FACIS_SEARCH) &&
              selectedAdditionalProperties?.facis_search?.subtype === 1
            }
            isIncluded={isIncluded(ScreeningProductType.FACIS_SEARCH)}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            disabled={facisLevel1Disabled}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
        {!recommendationsSection && (
          <FACISLevel3
            id={`${ScreeningProductType.FACIS_SEARCH}-level-3`}
            price={facisLevel3Price}
            isAdded={
              isAdded(ScreeningProductType.FACIS_SEARCH) &&
              selectedAdditionalProperties?.facis_search?.subtype === 3
            }
            isIncluded={isIncluded(ScreeningProductType.FACIS_SEARCH)}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            disabled={facisLevel3Disabled}
            recommendedAddOnsList={recommendedAddOnsList}
            screeningSectionId={screeningSectionId}
            sectionName={t('sectionName')}
          />
        )}
      </StyledFlex>
    </ScreeningSection>
  );
};

export default FACISSection;
