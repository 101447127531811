import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { GenericObject } from '@dashboard-experience/utils';
import { SECONDS } from 'utils';
import {
  fetchBillingAddress,
  postBillingAddress,
  putBillingContacts,
  PostAddressParams,
  PutContactsParams,
  getPaymentMethods,
  setDefaultPayment,
  SetDefaultParams,
  verifyBA,
  VerifyBAParams,
  postPaymentMethod,
  PostPaymentMethodParams,
  deletePaymentMethod,
  DeletePaymentMethodParams,
  authorizePaymentAction,
  AuthorizePaymentActionParams,
  getTaxExemptionCertificates,
  enableTaxForCustomer,
  EnableTaxForCustomerParams,
} from './actions';
import { toastError, toastSuccess } from '../../actions';
import {
  PAYMENT_METHODS_QUERY_KEY,
  BILLING_CUSTOMER_QUERY_KEY,
} from './constants';

export const useFetchBillingAddress = (accountId: string) => {
  const request = () => fetchBillingAddress(accountId);
  const key: AnyQueryKey = [BILLING_CUSTOMER_QUERY_KEY];

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const usePostBillingAddress = () => {
  const dispatch = useDispatch();
  const request = (params: PostAddressParams) => postBillingAddress(params);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(
        toastSuccess('Billing Address information successfully updated'),
      );
    },
    onError: () => {
      dispatch(
        toastError(
          'There was an error updating your Billing Address information.',
        ),
      );
    },
  });

  return { call, result };
};

export const usePutBillingContacts = () => {
  const dispatch = useDispatch();
  const request = (params: PutContactsParams) => putBillingContacts(params);
  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(
        toastSuccess('Billing Contacts information successfully updated'),
      );
    },
    onError: () => {
      dispatch(
        toastError(
          'There was an error updating your Billing Contacts information.',
        ),
      );
    },
  });

  return { call, result };
};

export const useGetPaymentMethods = (accountId: string) => {
  const key: AnyQueryKey = [PAYMENT_METHODS_QUERY_KEY];
  const request = () => getPaymentMethods(accountId);

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useSetDefaultPayment = () => {
  const dispatch = useDispatch();
  const request = (params: SetDefaultParams) => setDefaultPayment(params);

  const [setDefaultPaymentCall] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(PAYMENT_METHODS_QUERY_KEY);
      queryCache.invalidateQueries(BILLING_CUSTOMER_QUERY_KEY);
      dispatch(toastSuccess('Default payment method updated successfully.'));
    },
    onError: () => {
      dispatch(
        toastError('There was an error updating your default payment method.'),
      );
    },
  });

  return { setDefaultPaymentCall };
};

export const useVerifyBA = () => {
  const dispatch = useDispatch();
  const request = (params: VerifyBAParams) => verifyBA(params);

  const [verifyBACall, verifyBAResult] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(PAYMENT_METHODS_QUERY_KEY);
      queryCache.invalidateQueries(BILLING_CUSTOMER_QUERY_KEY);
      dispatch(toastSuccess('Bank account successfully verified'));
    },
    onError: () => {
      dispatch(toastError('There was an error verifying your bank account.'));
    },
  });

  return { verifyBACall, verifyBAResult };
};

export const usePostPaymentMethod = () => {
  const dispatch = useDispatch();
  const request = (params: PostPaymentMethodParams) =>
    postPaymentMethod(params);
  const [postPaymentMethodCall, postPaymentMethodResult] = useMutation(
    request,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(PAYMENT_METHODS_QUERY_KEY);
        queryCache.invalidateQueries(BILLING_CUSTOMER_QUERY_KEY);
        dispatch(toastSuccess('Payment method successfully added.'));
      },
      onError: () => {},
    },
  );

  return { postPaymentMethodCall, postPaymentMethodResult };
};

export const useDeletePaymentMethod = () => {
  const dispatch = useDispatch();
  const request = (params: DeletePaymentMethodParams) =>
    deletePaymentMethod(params);

  const [deletePaymentMethodCall] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(PAYMENT_METHODS_QUERY_KEY);
      queryCache.invalidateQueries(BILLING_CUSTOMER_QUERY_KEY);
      dispatch(toastSuccess('Payment method successfully deleted.'));
    },
    onError: (error: GenericObject) => {
      dispatch(
        toastError(
          'There was an error deleting your payment method.',
          error.response?.data?.error,
          20 * SECONDS,
        ),
      );
    },
  });

  return { deletePaymentMethodCall };
};

export const useAuthorizePaymentAction = (successHandler: Function) => {
  const dispatch = useDispatch();
  const request = (params: AuthorizePaymentActionParams) =>
    authorizePaymentAction(params);

  const [authorizePaymentActionCall, authorizePaymentActionResult] =
    useMutation(request, {
      onSuccess: () => {
        dispatch(toastSuccess('Success!'));
        successHandler();
      },
      onError: (error: GenericObject) => {
        dispatch(
          toastError(
            'There was an error performing the action on your account.',
            error.response.data.error,
            20 * SECONDS,
          ),
        );
      },
    });

  return { authorizePaymentActionCall, authorizePaymentActionResult };
};

export const useGetTaxExemptionCertificates = (accountId: string) => {
  const key: AnyQueryKey = ['tax_exemption_certificates', accountId];
  const request = () => getTaxExemptionCertificates(accountId);

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useEnableTaxForCustomer = () => {
  const dispatch = useDispatch();
  const request = (params: EnableTaxForCustomerParams) =>
    enableTaxForCustomer(params);

  const [enableTaxForCustomerCall, enableTaxForCustomerResult] = useMutation(
    request,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(BILLING_CUSTOMER_QUERY_KEY);
      },
      onError: () => {
        dispatch(toastError('There was an error performing this action'));
      },
    },
  );

  return { enableTaxForCustomerCall, enableTaxForCustomerResult };
};
