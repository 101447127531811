import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const ResponsibilitiesTitle = styled.div`
  flex: 1 0 0;
  color: var(--aqua-600, #117292);
  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm-eyebrow, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-line-height-sm-eyebrow, 14px);
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const RoleNameText = styled.div`
  align-self: stretch;
  margin-top: 8px;
  color: var(--text-icon-black-primary, #1a2026);
  font-family: var(--font-family-title, 'National 2');
  font-size: var(--font-size-xl-H3, 24px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-3xl-H3, 30px);
  letter-spacing: 0.24px;
`;

export const ResponsibilitiesText = styled.div`
  margin-bottom: 8px;
  color: var(--text-icon-black-primary, #1a2026);
  font-family: var(--font-family-title, 'National 2');
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-line-height-lg, 20px);
  letter-spacing: 0.16px;
  margin-top: 40px;
`;

export const SelectAllText = styled.div`
  height: 18px;
  align-self: stretch;
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65));
  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg, 20px);
  margin-bottom: 16px;
`;

export const TellUsMoreText = styled.div`
  height: 20px;
  align-self: stretch;
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65));
  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg, 20px);
`;

export const WhatShouldWeCallThisRoleText = styled.div`
  height: 20px;
  align-self: stretch;
  color: var(--text-icon-black-primary, #1a2026);
  font-family: var(--font-family-title, 'National 2');
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-line-height-lg, 20px);
  letter-spacing: 0.16px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  align-self: stretch;
  border-top: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  margin-top: 32px;
`;
