import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import {
  DecisionType,
  useGetAdjudicationSubtypes,
  useSetVariantDecision,
} from 'modules/adjudication/api';
import { useAdverseActionable } from 'api/reports/hooks';
import { MobilityVariantCheckboxGroup } from 'modules/adjudication/ui/common';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';
import { namespace } from 'modules/adjudication/locales';
import { useFetchAssessmentVariantRegitrations } from 'api/assessmentVariants/hooks';
import { useModalState, useDispatch } from '../../../context';
import Content from '../content';

const Container = styled.div`
  overflow: auto;
  background: ${colors.uiGrey0};
`;

const StyledMessage = styled.div`
  margin-bottom: 1rem;
`;

const CheckboxGroupContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

type NavigationConfig = {
  onClick: () => void;
  name: string;
  disabled?: boolean;
};

const DisqualifyConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const transitionToScreen = useModalState('transitionToScreen');
  const onClose = useModalState('onClose');
  const { adjudicationSubtypes } = useGetAdjudicationSubtypes();
  const { adverseActionable, isLoading } = useAdverseActionable();
  const {
    data: assessmentVariantRegistrations,
  }: { data: AssessmentVariantRegistration[] } =
    useFetchAssessmentVariantRegitrations();
  const {
    call: setDecision,
    result: decisionResult,
    isLoading: isSaving,
  } = useSetVariantDecision();
  const [selectedRegistrations, setSelectedRegistrations] = useState<
    AssessmentVariantRegistration[]
  >([]);
  const basePath = `${namespace}:report.actions.disqualify`;

  const saveDisqualifyDecision = useCallback(() => {
    selectedRegistrations.forEach((registration) => {
      if (registration.current_variant?.adjudication?.kind !== 'negative') {
        setDecision({
          variantId: registration.current_variant!.id,
          decision: 'disqualified' as DecisionType,
        });
      }
    });
  }, [setDecision, selectedRegistrations]);

  const hasDisqualifiedVariants = assessmentVariantRegistrations.some(
    (registration) =>
      registration.current_variant?.adjudication?.kind === 'negative',
  );

  useEffect(() => {
    if (decisionResult.status === 'success') {
      setSelectedRegistrations([]);
    }
  }, [decisionResult.status]);

  const createNavigation = (
    nextConfig: NavigationConfig | null,
    closeConfig: NavigationConfig | null = null,
  ) => ({
    back: null,
    next: nextConfig,
    skip: null,
    close: closeConfig,
  });

  useEffect(() => {
    let nextConfig: NavigationConfig | null = null;
    const closeConfig = {
      onClick: onClose,
      name: t(`${basePath}.modal.close`),
    };

    if (
      !isLoading &&
      adverseActionable?.enabled &&
      hasDisqualifiedVariants &&
      selectedRegistrations.length === 0
    ) {
      const nextScreen =
        adjudicationSubtypes?.length >= 2 ? 'subtypes' : 'adverse_items';

      nextConfig = {
        onClick: () => transitionToScreen(nextScreen),
        name: t(`${basePath}.modal.next_step`),
      };
    } else {
      nextConfig = {
        onClick: saveDisqualifyDecision,
        name: t(`${basePath}.modal.save`),
        disabled: isSaving || selectedRegistrations.length === 0,
      };
    }

    dispatch({
      type: 'SET_NAVIGATION',
      payload: createNavigation(nextConfig, closeConfig),
    });
  }, [
    dispatch,
    isSaving,
    saveDisqualifyDecision,
    t,
    selectedRegistrations,
    isLoading,
    adverseActionable,
    hasDisqualifiedVariants,
  ]);

  return (
    <Container>
      <Content>
        <StyledMessage>{t(`${basePath}.modal.title`)}</StyledMessage>
        <CheckboxGroupContainer>
          <StyledMessage>{t(`${basePath}.modal.description`)}</StyledMessage>
          <MobilityVariantCheckboxGroup
            adjudicationKind='negative'
            onSelectionsChange={setSelectedRegistrations}
          />
        </CheckboxGroupContainer>
      </Content>
    </Container>
  );
};

export default DisqualifyConfirmation;
