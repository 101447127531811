import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import creditCardChecked from 'components/AddScreenings/shared/svg/credit-card-checked.svg';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import {
  CreditReportStatus,
  ScreeningProductType,
} from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import CreditReport from './CreditReport';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  status: AddonsT.CreditReportStatusType;
  recommendedAddOnsList?: Set<string>;
};

const CreditReportSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
  status,
  recommendedAddOnsList,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.creditReport',
  });
  const screeningSectionId = 'credit-report';

  const creditReportPrice =
    status === CreditReportStatus.ENABLED
      ? getPricesByScreeningType(
          addOnPrices,
          ScreeningProductType.CREDIT_REPORT,
        )
      : t('priceVaries');

  const shouldRender = (addOn: string) => {
    return !recommendedAddOnsList || recommendedAddOnsList.has(addOn);
  };

  return (
    <>
      {shouldRender(ScreeningProductType.CREDIT_REPORT) && (
        <ScreeningSection
          id={screeningSectionId}
          name={t('sectionName')}
          icon={creditCardChecked}
          recommendedAddOnsList={recommendedAddOnsList}
          screeningSectionId={screeningSectionId}
        >
          <StyledFlex flexDirection='column'>
            <CreditReport
              id={ScreeningProductType.CREDIT_REPORT as string}
              price={creditReportPrice}
              isAdded={isAdded(ScreeningProductType.CREDIT_REPORT)}
              isIncluded={isIncluded(ScreeningProductType.CREDIT_REPORT)}
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              status={status}
              recommendedAddOnsList={recommendedAddOnsList}
              screeningSectionId={screeningSectionId}
              sectionName={t('sectionName')}
            />
          </StyledFlex>
        </ScreeningSection>
      )}
    </>
  );
};

export default CreditReportSection;
