import React, { useCallback } from 'react';
import { GenericObject, Report } from 'types';
import { useLogPDFDownload } from 'api/documents';
import { M } from '@dashboard-experience/mastodon';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';

type PdfLinkProps = {
  item: GenericObject;
  report: Report;
  closeWithAction: Function;
  showPdfEncryptionNote?: boolean;
  customDescription?: string;
};

const PdfLink: React.FC<PdfLinkProps> = ({
  item,
  report,
  closeWithAction,
  showPdfEncryptionNote = false,
  customDescription,
}) => {
  const { docType, type, link, text, isPdfReport, disabled, historical } = item;
  const { id } = report;
  const isConsent = docType === 'consent';

  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const trackPdfLink = useCallback(() => {
    closeWithAction();

    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Document Selected': item.text,
        Report: reportProperties,
      });
    }
  }, [closeWithAction, currentUser, item.text, reportProperties, trackEvent]);

  const trackingType = useCallback(() => {
    const actualType = isPdfReport && type ? type.replace('pdf_', '') : docType;

    return historical === true ? `historical_${actualType}` : actualType;
  }, [type, docType, historical, isPdfReport]);

  const { call: logDownload } = useLogPDFDownload({
    reportId: id,
    type: trackingType(),
  });

  const handleClick = useCallback(() => {
    window.open(link, '_blank');
    if (isConsent) {
      trackPdfLink();
    } else {
      trackPdfLink();
      logDownload();
    }
  }, [isConsent, trackPdfLink, logDownload, link]);

  const showItem = item && (isConsent || !disabled);

  const encryptionNote =
    'Report PDF is password protected with the last four digits of the candidate’s SSN';
  // TODO: Remove showPdfEncryptionNote. This was probably legacy and we are checking the type here now.
  const showEncryptionNote =
    showPdfEncryptionNote && type === 'pdf_report_encrypted';

  const descriptions = [];
  if (customDescription) descriptions.push(customDescription);
  if (showEncryptionNote) descriptions.push(encryptionNote);
  const description = descriptions.join(' - ');

  return showItem ? (
    <M.ActionMenuItem
      title={text}
      kind='node'
      actionNode={<M.Icon icon='Download' size='16' />}
      onClick={handleClick}
      description={description}
    />
  ) : null;
};

export default PdfLink;
